import React from "react";
import "./ContainerLoader.css";
import { Box, CircularProgress } from "@mui/material";

function ContainerLoader({ loading, relative, children, ...props }) {
  return (
    <>
      <Box
        className={`${!relative ? "container-loader" : ""} ${
          loading ? "" : "out"
        }`}
        sx={{
          display: "flex",
          alignItems: "center",
          py: 3,
        }}
      >
        {loading === true ? (
          <CircularProgress
            style={relative && { height: "100%", width: "100%" }}
          />
        ) : (
          <React.Fragment />
        )}
      </Box>
      {children && !loading ? (
        <Box {...props}>{children}</Box>
      ) : (
        <React.Fragment />
      )}
    </>
  );
}

export default ContainerLoader;
