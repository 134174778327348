import { gql } from "@apollo/client";
import {
  AGENCY_TYPE_FRAGMENTS,
  DOMAIN_FRAGMENTS,
  WEBSITE_FRAGMENTS,
  WEBSITE_TEMPLATE_FRAGMENTS,
  WEBSITE_PLAN_FRAGMENTS,
  AGENCY_PORTFOLIO_FRAGMENTS,
  WEBSITE_SERVICE_FRAGMENTS,
} from "./fragments";

// Queries
const GET_DOMAINS = gql`
  ${DOMAIN_FRAGMENTS}
  query {
    domains {
      ...DomainParts
    }
  }
`;
const GET_WEBSITES = gql`
  ${WEBSITE_FRAGMENTS}
  query {
    websites {
      ...WebsiteParts
    }
  }
`;
const GET_WEBSITE = gql`
  ${WEBSITE_FRAGMENTS}
  query Website($id: ID) {
    website(id: $id) {
      ...WebsiteParts
    }
  }
`;
const GET_TEMPLATES = gql`
  ${WEBSITE_TEMPLATE_FRAGMENTS}
  query {
    websiteTemplates {
      ...WebsiteTemplateParts
    }
  }
`;

const GET_WEBSITE_PLANS = gql`
  ${WEBSITE_PLAN_FRAGMENTS}
  query ($annual: Boolean, $customDomain: Boolean, $templateId: ID) {
    websitePlans(
      annual: $annual
      customDomain: $customDomain
      templateId: $templateId
    ) {
      ...WebsitePlanParts
    }
  }
`;

const GET_PORTFOLIOS = gql`
  ${AGENCY_PORTFOLIO_FRAGMENTS}
  query {
    portfolios {
      ...AgencyPortfolioParts
    }
  }
`;

const GET_SERVICES = gql`
  ${WEBSITE_SERVICE_FRAGMENTS}
  query searchServices($name: String) {
    services(name: $name) {
      ...WebsiteServiceParts
    }
  }
`;

const GET_AGENCY_SERVICES = gql`
  ${WEBSITE_SERVICE_FRAGMENTS}
  query {
    agencyServices {
      ...WebsiteServiceParts
    }
  }
`;

// Mutations
const VERIFY_DOMAIN = gql`
  ${DOMAIN_FRAGMENTS}
  mutation VerifyDomain($id: ID!) {
    verifyDomain(id: $id) {
      domain {
        ...DomainParts
      }
    }
  }
`;

const UPDATE_AGENCY = gql`
  ${AGENCY_TYPE_FRAGMENTS}
  mutation UpdateAgency(
    $agencyId: ID
    $logo: Upload
    $ownerId: ID
    $name: String
    $themeColor: String
    $subdomain: String
  ) {
    updateAgency(
      agencyId: $agencyId
      logo: $logo
      ownerId: $ownerId
      name: $name
      themeColor: $themeColor
      subdomain: $subdomain
    ) {
      agency {
        ...AgencyParts
      }
    }
  }
`;
const CREATE_DOMAIN = gql`
  ${DOMAIN_FRAGMENTS}
  mutation UpdateAgency($domain: String!) {
    createDomain(domain: $domain) {
      domain {
        ...DomainParts
      }
    }
  }
`;

const DELETE_DOMAIN = gql`
  mutation DeleteDomain($domainId: ID!) {
    deleteDomain(domainId: $domainId) {
      deleted
    }
  }
`;

const UPDATE_AGENCY_PORTFOLIO = gql`
  ${AGENCY_PORTFOLIO_FRAGMENTS}
  mutation UpdateAgencyPortfolio(
    $portfolioId: ID
    $portfolioType: ID
    $title: String
    $description: String
    $shortDescription: String
    $link: String
    $displayOnWebsite: Boolean
    $listOrder: Int
    $previewImage: Upload
    $thumbnail: Upload
    $dateCompleted: Date
  ) {
    updateAgencyPortfolio(
      portfolioId: $portfolioId
      previewImage: $previewImage
      thumbnail: $thumbnail
      title: $title
      link: $link
      description: $description
      portfolioType: $portfolioType
      shortDescription: $shortDescription
      displayOnWebsite: $displayOnWebsite
      listOrder: $listOrder
      dateCompleted: $dateCompleted
    ) {
      portfolio {
        ...AgencyPortfolioParts
      }
    }
  }
`;

const UPDATE_WEBSITE = gql`
  ${WEBSITE_FRAGMENTS}
  mutation UpdateWebsite(
    $name: String
    $websiteId: ID
    $planId: ID!
    $domainId: ID
    $subdomain: String
    $subUrl: String
    $setActive: Boolean
    $templateId: ID
    $instagram: String
    $facebookPage: String
    $linkedin: String
    $twitter: String
    $tiktok: String
    $contactEmail: String
    $contactNumber: String
    $facebookPixelId: String
    $googleAnalyticsId: String
    $gtagId: String
    $tiktokPixelId: String
    $pageTitle: String
    $pageSlogan: String
    $aboutUs: String
    $brandDisplayName: String
  ) {
    updateWebsite(
      name: $name
      websiteId: $websiteId
      planId: $planId
      setActive: $setActive
      templateId: $templateId
      domainId: $domainId
      subdomain: $subdomain
      subUrl: $subUrl
      instagram: $instagram
      facebookPage: $facebookPage
      linkedin: $linkedin
      twitter: $twitter
      tiktok: $tiktok
      contactEmail: $contactEmail
      contactNumber: $contactNumber
      facebookPixelId: $facebookPixelId
      googleAnalyticsId: $googleAnalyticsId
      gtagId: $gtagId
      tiktokPixelId: $tiktokPixelId
      pageTitle: $pageTitle
      pageSlogan: $pageSlogan
      aboutUs: $aboutUs
      brandDisplayName: $brandDisplayName
    ) {
      website {
        ...WebsiteParts
      }
    }
  }
`;
const DELETE_WEBSITE = gql`
  mutation DeleteWebsite($websiteId: ID!) {
    deleteWebsite(websiteId: $websiteId) {
      deleted
    }
  }
`;
const DELETE_AGENCY_PORTFOLIO = gql`
  mutation DeleteAgencyPortfolio($portfolioId: ID!) {
    deleteAgencyPortfolio(portfolioId: $portfolioId) {
      deleted
    }
  }
`;

const CREATE_WEBSITE_SERVICE = gql`
  ${WEBSITE_SERVICE_FRAGMENTS}
  mutation CreateWebsiteService($name: String!, $image: Upload) {
    createWebsiteService(name: $name, image: $image) {
      service {
        ...WebsiteServiceParts
      }
    }
  }
`;

const ASSIGN_SERVICES_TO_AGENCY = gql`
  ${WEBSITE_SERVICE_FRAGMENTS}
  mutation AssignServicesToAgency($serviceIds: [ID]!) {
    assignServicesToAgency(serviceIds: $serviceIds) {
      agency {
        servicesOffered {
          ...WebsiteServiceParts
        }
      }
    }
  }
`;

const agencyQueries = {
  getDomains: GET_DOMAINS,
  getWebsites: GET_WEBSITES,
  getWebsite: GET_WEBSITE,
  getPortfolios: GET_PORTFOLIOS,
  updateAgency: UPDATE_AGENCY,
  updateAgencyPortfolio: UPDATE_AGENCY_PORTFOLIO,
  deleteAgencyPortfolio: DELETE_AGENCY_PORTFOLIO,
  createWebsiteService: CREATE_WEBSITE_SERVICE,
  createDomain: CREATE_DOMAIN,
  verifyDomain: VERIFY_DOMAIN,
  deleteDomain: DELETE_DOMAIN,
  updateWebsite: UPDATE_WEBSITE,
  deleteWebsite: DELETE_WEBSITE,
  getTemplates: GET_TEMPLATES,
  getWebsitePlans: GET_WEBSITE_PLANS,
  getServices: GET_SERVICES,
  getAgencyServices: GET_AGENCY_SERVICES,
  assignServicesToAgency: ASSIGN_SERVICES_TO_AGENCY,
};

export default agencyQueries;
