import { matchIsValidColor } from "mui-color-input";

export const validateForm = (fields, updateField, listLoop = null) => {
  let valid = true;
  const list = listLoop ?? Object.keys(fields);
  for (const i in list) {
    const key = list[i];
    const { update, isValid } = isFormFieldValid(fields[key]);
    updateField && updateField(key, update);
    if (valid && !isValid) {
      valid = false;
      if (!updateField) return false;
    }
  }
  return valid;
};

export const isEmpty = (val) => !val || (isString(val) && val.trim() === "");

export const isFormFieldValid = ({
  type,
  value: val,
  required: reqPass,
  requiredConditional,
  errorText,
  label,
}) => {
  const required = !!requiredConditional || reqPass;
  let update = {};
  const integer = type === "number";
  const isValueEmpty = isEmpty(val);
  if (required && isValueEmpty) {
    update.error = errorText ?? `Please enter a ${label}`;
  } else if (type === "email" && !isValueEmpty && !isEmailValid(val)) {
    update.error = "Please enter a valid Email";
  } else if (type == "color" && !isValueEmpty && !matchIsValidColor(val)) {
    update.error = "Invalid Colour";
  } else {
    update.error = "";
  }
  if (
    !integer ||
    (integer && val === "") ||
    (integer && val.trim() !== "" && !isNaN(val))
  ) {
    update.value = val;
  }
  return { isValid: !update?.error, update: update };
};

const dateIsoFormats = {
  date: "YYYY-MM-DD",
  time: "HH:mm:ss",
};

export const getSubmitVariables = (fields) =>
  Object.keys(fields).reduce((dat, item) => {
    const field = fields[item];
    let value;
    const type = field.type;
    if (
      field.value != null &&
      typeof field.value === "string" &&
      field.value.trim() === ""
    ) {
      value = null;
    } else if (type === "date" || type === "time") {
      value = field.value.format(dateIsoFormats[type]);
    } else if (type === "autocomplete") {
      console.log(fields[item].value);
      if (field.multiple) {
        value = field.map(field?.genVariableList ?? ((i) => i.id));
      } else {
        value = field.value.id;
      }
    } else {
      value = fields[item].value;
    }
    // const value =
    //   (field.value != null &&
    //   typeof field.value === "string" &&
    //   field.value.trim() === ""
    //     ? null
    //     : fields[item].value) ?? fields[item].default;
    if (!(value == null)) dat[item] = value ?? fields[item].default;
    return dat;
  }, {});

const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmailValid = (email) => emailRe.test(email.trim().toLowerCase());

export const isFunction = (functionToCheck) =>
  functionToCheck &&
  (typeof functionToCheck === "function" ||
    {}.toString.call(functionToCheck) === "[object Function]");

export const isString = (val) =>
  typeof val === "string" || val instanceof String;
