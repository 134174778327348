import React from "react";
import agencyQueries from "../../../queries/agency";
import Section from "../Section";
import QueryLoading from "../../gql/QueryLoading";
import { useStateValue } from "../../../StateProvider";
import { paths } from "../../../utils/dashboardConstants";
import { navigate } from "@reach/router";
import { Box } from "@mui/material";
import Domains, { AddDomain } from "./website_manager/Domains";
import Websites, { UpdateWebsite } from "./website_manager/Websites";
import ActiveWebsite from "./website_manager/ActiveWebsite";

const WebsiteManager = () => {
  const [{ user }] = useStateValue();
  const [updateDomains, setUpdateDomains] = React.useState(null);

  const [domains, setDomains] = React.useState();
  React.useEffect(() => {
    if (!user.agency) {
      navigate(paths.agency.index);
    }
  }, []);
  if (!user.agency) {
    return <></>;
  }
  return (
    <>
      <Section>
        <QueryLoading
          query={agencyQueries.getWebsite}
          listKey="website"
          Render={ActiveWebsite}
          renderProps={{ domains: domains }}
          forceRender
        />
      </Section>
      {/* <Section title="Website Configurator">
                <QueryLoading query={ }/>
            </Section> */}
      <Section
        title="Custom Domains"
        subtitle="To use a custom domain it has to be added and verified before you can assign it to your website"
        titleEnd={
          updateDomains && (
            <Box ml="auto">
              <AddDomain updateDomains={updateDomains} />
            </Box>
          )
        }
      >
        <QueryLoading
          listKey="domains"
          smallAlert
          listTitle="Domain"
          Render={Domains}
          query={agencyQueries.getDomains}
          setUpdateQs={setUpdateDomains}
          setData={setDomains}
        />
      </Section>

      {/* <Section
        title="All Websites"
        titleEnd={
          updateWebsite && (
            <Box ml="auto">
              <UpdateWebsite updateQs={updateWebsite} domains={domains} />
            </Box>
          )
        }
      >
        <QueryLoading
          listKey="websites"
          smallAlert
          listTitle="Website"
          Render={Websites}
          query={agencyQueries.getWebsites}
          setUpdateQs={setUpdateWebsite}
        />
      </Section> */}
    </>
  );
};

export default WebsiteManager;
