import React from "react";
import { useSnackbar } from "notistack";
import { Box } from "@mui/system";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Chip,
} from "@mui/material";
import { displayDateFromStr } from "../../../../utils/functions";
import agencyQueries from "../../../../queries/agency";
import Button from "../../../forms/Button";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { grey } from "@mui/material/colors";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import MutationLoading from "../../../gql/MutationLoading";

export const AddDomain = ({ updateDomains }) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Button
      modal={{
        label: "create-domain",
        title: "Add a Domain",
        dialogProps: {
          scroll: "paper",
          fullWidth: true,
          maxWidth: "sm",
        },
        form: true,
        renderProps: {
          genFields: {
            domain: {
              value: "",
              label: "Domain",
              required: true,
            },
          },
          actions: [
            {
              mutation: {
                dataKey: "createDomain",
                query: agencyQueries.createDomain,
                updateValue: ({ domain }) => {
                  if (domain == null) {
                    enqueueSnackbar(
                      "An error occurred when creating your domain",
                      {
                        variant: "error",
                      }
                    );
                    return null;
                  }
                  updateDomains && updateDomains(domain.id, domain, false);
                },
                successMessage: `Your domain has been created Successfully!`,
                buttonProps: {
                  children: "Create",
                },
              },
              resetOnSubmit: true,
            },
          ],
        },
      }}
    >
      Add
    </Button>
  );
};

export const DeleteDomain = ({ updateDomains, domain }) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Button
      color="error"
      modal={{
        label: "delete-domain",
        title: "Delete Domain",
        dialogProps: {
          scroll: "paper",
          fullWidth: true,
          maxWidth: "sm",
        },
        form: true,
        renderProps: {
          genFields: {
            domainId: {
              value: domain.id,
              required: true,
              type: "hidden",
            },
          },
          actions: [
            {
              mutation: {
                dataKey: "deleteDomain",
                query: agencyQueries.deleteDomain,
                updateValue: ({ deleted }) => {
                  if (!deleted) {
                    enqueueSnackbar(
                      "An error occurred when creating your domain",
                      {
                        variant: "error",
                      }
                    );
                    return null;
                  }
                  updateDomains && updateDomains(domain.id, {}, true);
                },
                successMessage: `Your domain has been deleted Successfully!`,
                buttonProps: {
                  children: "Delete",
                  // color: "error",
                },
              },
              resetOnSubmit: true,
            },
          ],
        },
      }}
    >
      Delete
    </Button>
  );
};

const Domains = ({ data, updateQs }) => {
  const { enqueueSnackbar } = useSnackbar();
  return data.map((domain) => (
    <Accordion key={domain.id}>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon />}
        aria-controls="domains-content"
        id={`domain-${domain.id}`}
      >
        {/* <Stack direction="row"> */}
        <Typography>{domain.domain}</Typography>
        <Box ml="auto" mr={2}>
          {domain.verified ? (
            <Chip
              variant="outlined"
              label="Verified"
              color="success"
              size="small"
              deleteIcon={<DoneRoundedIcon />}
              onDelete={() => {}}
              // avatar={<DoneRoundedIcon color="inherit" />}
            />
          ) : (
            <>
              <Chip
                color="error"
                variant="outlined"
                // deleteIcon={<DoneIcon />}
                onDelete={() => {}}
                label="Not Verified"
                size="small"
                deleteIcon={<CloseRoundedIcon />}
              />
            </>
          )}
        </Box>
        {/* </Stack> */}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container sx={{ borderTop: 1, pt: 3 }}>
          {domain.verified ? (
            <>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ mb: 2 }} variant="h5" fontWeight={700}>
                  Domain Info
                </Typography>
                <Typography>
                  Verified: {displayDateFromStr(domain.verified)}
                  <br />
                  <br />
                  Domain Provider: {domain.domainHost ?? "N/A"}
                  <br />
                  <br />
                  DNS Provider: {domain.dnsHost ?? "N/A"}
                  <br />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ mb: 2 }} variant="h5" fontWeight={700}>
                  Domain Emails
                </Typography>
                {/* todo add domain email funcitonality*/}
                <Typography>
                  You currently have no emails linked to this domain.
                  <br />
                  Contact us to set one up!
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography>
                To verify your domain, add the following text record to "
                {domain.domain}"
              </Typography>
              <Box
                p={2}
                my={2}
                borderRadius={4}
                backgroundColor={grey[100]}
                display="flex"
                alignItems="center"
              >
                <Typography>{domain.verifyTextRecord}</Typography>
                <Box ml={2}>
                  <MutationLoading
                    dataKey="verifyDomain"
                    query={agencyQueries.verifyDomain}
                    variables={{ id: domain.id }}
                    updateValue={({ domain }) => {
                      console.log("domain", domain);
                      if (!domain) {
                        enqueueSnackbar(
                          "An error occurred while trying to verify your domain",
                          {
                            variant: "error",
                          }
                        );
                        return null;
                      }
                      updateQs(
                        domain.id,
                        domain,
                        false
                        //   "domains",
                        //   domain.id
                      );
                    }}
                    ignoreSuccessMessage
                    buttonProps={{
                      tooltip: { title: "Verify" },
                      iconButton: true,
                      children: <LoopRoundedIcon />,
                      size: "small",
                    }}
                  />
                </Box>
              </Box>
              <Typography variant="body2">
                Please note it can take up to 12 hours for the dns records to
                update.
                <br />
                Once verified you will be able to assign it to your website and
                request domain email creations from our team.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box>
              <DeleteDomain updateDomains={updateQs} domain={domain} />
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  ));
};

export default Domains;
