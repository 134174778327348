import { gql } from "@apollo/client";
import { AUTH_FRAGMENTS } from "./fragments";

const GET_ME = gql`
  ${AUTH_FRAGMENTS}
  query {
    me {
      ...UserParts
    }
  }
`;

const TOKEN_GET = gql`
  mutation getTokenAuth($email: String!, $password: String!) {
    action: tokenAuth(username: $email, password: $password) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;
const TOKEN_REFRESH = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;
const TOKEN_REVOKE = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;
const DELETE_TOKEN = gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
  }
`;
const DELETE_REFRESH_TOKEN = gql`
  mutation {
    deleteRefreshTokenCookie {
      deleted
    }
  }
`;
const LOGOUT_QUERIES = gql`
  mutation RevokeToken($refreshToken: String!) {
    deleteRefreshTokenCookie {
      deleted
    }
    deleteTokenCookie {
      deleted
    }
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;

const GET_USERS = gql`
  ${AUTH_FRAGMENTS}
  query ($excludeUser: Boolean) {
    allUsers(excludeUser: $excludeUser) {
      ...UserParts
    }
  }
`;

const GET_INITIAL_USER_DATA = gql`
  ${AUTH_FRAGMENTS}
  query {
    me {
      ...UserParts
    }
  }
`;

const CREATE_RESET_PASSWORD_CODE = gql`
  mutation CreateResetPasswordCode(
    $email: String
    $userId: ID
    $forceResend: Boolean
  ) {
    createResetPasswordCode(
      userId: $userId
      email: $email
      forceResend: $forceResend
    ) {
      email
      expiryDate
      message
    }
  }
`;
const VERIFY_RESET_PASSWORD_CODE = gql`
  mutation VerifyResetPasswordCode($email: String!, $code: String!) {
    verifyResetPasswordCode(email: $email, code: $code) {
      valid
    }
  }
`;
const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $email: String
    $code: String
    $confirmPassword: String!
    $password: String!
  ) {
    updatePassword(
      code: $code
      confirmPassword: $confirmPassword
      email: $email
      password: $password
    ) {
      updated
      invalidCode
    }
  }
`;
const authQueries = {
  getMe: GET_ME,
  getToken: TOKEN_GET,
  refreshToken: TOKEN_REFRESH,
  revokeToken: TOKEN_REVOKE,
  deleteToken: DELETE_TOKEN,
  deleteRefreshToken: DELETE_REFRESH_TOKEN,
  logoutQueries: LOGOUT_QUERIES,
  getInitialUserData: GET_INITIAL_USER_DATA,
  getUsers: GET_USERS,
  createResetPasswordCode: CREATE_RESET_PASSWORD_CODE,
  verifyResetPasswordCode: VERIFY_RESET_PASSWORD_CODE,
  updatePassword: UPDATE_PASSWORD,
};

export default authQueries;
