import React from "react";
import { Router } from "@reach/router";
import { navigate } from "gatsby";
import { Redirect } from "@reach/router";
import Layout from "../components/layout/Layout";
import GraphAuth from "../components/GraphAuth";
import Login from "../components/dashboard/login/Login";
import PrivateRoute from "../components/PrivateRoute";
import Nav from "../components/dashboard/Nav";
import { useGetQuery } from "../hooks/useGetQuery";
import { useStateValue } from "../StateProvider";
import { Box } from "@mui/material";
import { pages, paths } from "../utils/dashboardConstants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMatch } from "@reach/router";
import TemplatePreviewManager from "../components/agency_templates/TemplatePreviewManager";
const LoginAuth = () => {
  const params = useGetQuery();
  const [{ user }] = useStateValue();
  React.useEffect(() => {
    user && navigate(params.get("next") ?? "/dashboard/");
  }, [user]);
  return <Login />;
};
const DashboardBase = ({ user, ...props }) => {
  // const location = useLocation();
  // console.log(location.pathname);
  const match = useMatch(paths.agency.templatePreviewManager);
  if (match) return <TemplatePreviewManager />;
  return (
    <Box sx={{ display: "flex", background: "rgba(33,37,41,1)" }}>
      <Nav />
      <Box
        component="section"
        sx={{ backgroundColor: "rgb(248,249,250)", px: 3, pt: 3 }}
        boxShadow={10}
        borderRadius={3}
        flex="1"
      >
        <Router>
          {pages.map(({ component: Component, path, ...props }, i) => (
            <Component key={i} path={path} {...props} />
          ))}
          {/* <Redirect from="/" default to="/dashboard/" /> */}
        </Router>
      </Box>
    </Box>
  );
};

const DashboardInner = ({ path, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GraphAuth>
        <Router>
          <LoginAuth path="/dashboard/login/*" />
          <PrivateRoute default component={DashboardBase} />
          {/* <Navigate path={`/dashboard/login?next=${path}`} /> */}
        </Router>
      </GraphAuth>
    </LocalizationProvider>
  );
};

const Dashboard = ({ path, ...props }) => {
  return (
    <Layout path={path} {...props}>
      <DashboardInner path={path} />
    </Layout>
  );
};
export default Dashboard;
