import React from "react";
import QueryLoading from "../../../gql/QueryLoading";
import {
  Grid,
  Card,
  Stack,
  CardMedia,
  CardActions,
  Radio,
  CardActionArea,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import agencyQueries from "../../../../queries/agency";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const WebsiteConfiguratorPlanDisplay = ({
  data: plans,
  updateQs,
  formData,
  updateFormData,
}) => {
  const updateValue = (value) =>
    updateFormData({ field: "planId", value: value });
  const onRadioChange = (e) => {
    updateValue(e.target.value);
  };
  if (!plans) return <> </>;
  return (
    <Grid container spacing={2} justifyContent="center">
      {plans.map((plan) => (
        <Grid
          item
          md={4}
          // sm={4}
          xs={6}
          key={plan.id}
        >
          <Card
            // variant="outlined"
            sx={{ borderRadius: 2, height: "100%" }}
          >
            <CardActionArea
              onClick={() => updateValue(plan.id)}
              //   sx={{
              //     borderColor: formData?.templateId === template.id && "primary",
              //   }}
            >
              <CardContent sx={{ pb: 0 }}>
                <Stack>
                  <Typography variant="h6" fontWeight={700} component="div">
                    {plan.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {plan.description}
                  </Typography>
                  <Typography variant="h6" fontWeight={700} sx={{ mt: 3 }}>
                    £{plan.price} / {plan.annual ? "Year" : "Month"}
                  </Typography>
                  <List>
                    {plan.customDomain && (
                      <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                          <CheckCircleRoundedIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Custom Domain" />
                      </ListItem>
                    )}
                  </List>
                </Stack>
              </CardContent>
              <CardActions disableSpacing>
                <Radio
                  checked={formData?.planId?.value === plan.id}
                  onChange={onRadioChange}
                  value={plan.id}
                  name="plan-selector"
                  inputProps={{ "aria-label": "Plan Selector" }}
                />
              </CardActions>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const WebsiteConfiguratorPlanQuery = ({ setPlans, ...props }) => {
  return (
    <QueryLoading
      query={agencyQueries.getWebsitePlans}
      listKey="websitePlans"
      variables={{ templateId: props?.formData?.templateId?.value }}
      listEmpty={
        <>
          <strong>We are hard at work on our curated Plans</strong>
          <br /> They will be ready soon!
        </>
      }
      smallAlert
      listTitle="Plan"
      Render={WebsiteConfiguratorPlanDisplay}
      renderProps={props}
      setData={setPlans}
    />
  );
};

export default WebsiteConfiguratorPlanQuery;
