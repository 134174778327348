import React from "react";
import { navLinks } from "../../utils/dashboardConstants";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useMatch } from "@reach/router";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Collapse,
} from "@mui/material";
import { Link } from "gatsby";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
const CollapseWrap = ({ children, collapse, expanded }) => {
  return collapse ? (
    <Collapse
      sx={{ width: "100%" }}
      orientation="vertical"
      in={expanded}
      collapsedSize={0}
    >
      {children}
    </Collapse>
  ) : (
    children
  );
};

const NavItemRender = ({ page, borderList = false }) => {
  const isActive = useMatch(page.match ?? page.to ?? "undefined");
  const absoluteMatch = useMatch(page.to ?? "undefined");
  const [expanded, setExpanded] = React.useState(false);
  React.useEffect(() => {
    isActive ? setExpanded(true) : setExpanded(false);
  }, [isActive]);
  const toggleHover = (show) => {
    !isActive && setExpanded(show);
  };
  const linkProps = !page.to
    ? {}
    : page.to.startsWith("http") || page.to.startsWith("www.")
    ? {
        href: page.to,
        target: "_blank",
        rel: "noopener noreferrers",
        component: "a",
      }
    : { to: page.to, component: Link };
  const flexSx = page.sublinks
    ? {
        flexDirection: "column",
        alignItems: "start",
      }
    : {};
  return (
    <>
      <ListItem
        key={page.to}
        disablePadding
        onMouseEnter={() => page.sublinks && toggleHover(true)}
        onMouseLeave={() => page.sublinks && toggleHover(false)}
        className={isActive && "active"}
        sx={{
          ...flexSx,
          color: "rgba(255, 255, 255, 0.5)",
          my: borderList ? 1 : 0,

          "&:hover,&.active": { color: "rgb(255,255,255)" },
        }}
      >
        <ListItemButton
          // component={AnchorLink}
          {...linkProps}
          title={page.title}
          className={`anchor__link ${absoluteMatch ? "absolute__match" : ""}`}
          sx={{
            width: "100%",
            // py: borderList ? 2 : 1,
            py: 1,
            "&:hover,&.absolute__match": {
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              boxShadow: 3,
              borderRadius: 3,
            },
          }}
        >
          {page.icon && <page.icon sx={{ mr: 2 }} />}
          <ListItemText
            primary={page.title}
            primaryTypographyProps={{ sx: { fontWeight: 700 } }}
          />
          {page.sublinks && (
            <ChevronRightRoundedIcon
              sx={{
                transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
                transition: "all .3s",
              }}
            />
          )}
        </ListItemButton>
        {page.sublinks && (
          <NavLinkInner
            expanded={expanded}
            collapse={true}
            links={page.sublinks}
            indent={true}
          />
        )}
      </ListItem>
      {borderList && <hr className="nav__hr" />}
    </>
  );
};

const NavLinkInner = ({
  indent = false,
  links,
  collapse = false,
  expanded = false,
  borderList = false,
}) => {
  return (
    <CollapseWrap collapse={collapse} expanded={expanded}>
      <List sx={indent ? { ml: 2 } : {}}>
        {links.map((page, i) => (
          <NavItemRender page={page} borderList={borderList} key={i} />
        ))}
      </List>
    </CollapseWrap>
  );
};

const NavList = () => {
  return <NavLinkInner links={navLinks} borderList={true} />;
};

export default NavList;
