import React from "react";
import { navigate } from "gatsby";
import { useStateValue } from "../StateProvider";
// import { isLoggedIn } from "../services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const [{ user }] = useStateValue();
  if (!user && location.pathname !== `/dashboard/login/`) {
    navigate(`/dashboard/login/?next=${location.pathname}`);
    return null;
  }

  return <Component user={user} {...rest} />;
};

export default PrivateRoute;
