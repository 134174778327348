import React from "react";
import QueryLoading from "../../../gql/QueryLoading";
import {
  Grid,
  Card,
  Stack,
  CardMedia,
  CardActions,
  Radio,
  CardActionArea,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import agencyQueries from "../../../../queries/agency";
import Image from "../../../layout/ImageDisplay";
import GradientButton from "../../../forms/GradientButton";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
const WebsiteConfiguratorTemplateDisplay = ({
  data: templates,
  updateQs,
  formData,
  updateFormData,
}) => {
  const updateValue = (value) =>
    updateFormData({ field: "templateId", value: value });
  const onRadioChange = (e) => {
    updateValue(e.target.value);
  };
  if (!templates) return <> </>;
  return (
    <Grid container spacing={3} justifyContent="center">
      {templates.map((template) => (
        <Grid
          item
          md={4}
          // md={3}
          xs={6}
          sx={{ position: "relative" }}
          key={template.id}
        >
          <Card
            // variant="outlined"
            sx={{ borderRadius: 2, height: "100%" }}
            raised={template.id === formData?.templateId?.value}
          >
            <CardActionArea
              onClick={() => updateValue(template.id)}
              component="div"
              //   sx={{
              //     borderColor: formData?.templateId === template.id && "primary",
              //   }}
            >
              {/* {template?.thumbnailUrl && (
                <CardMedia
                  // component={Image}
                  component="img"
                  // height="194"
                  image={template.thumbnailUrl}
                  alt={template.name}
                />
              )} */}
              {template?.thumbnailUrl && (
                <CardMedia>
                  <Image src={template.thumbnailUrl} alt={template.name} />
                </CardMedia>
              )}
              <CardContent sx={{ pb: 0 }}>
                <Stack
                  direction="column"
                  alignItems="center"
                  alignContent="center"
                >
                  <Typography variant="h6" fontWeight={700} component="div">
                    {template.name}
                  </Typography>

                  {template.description && (
                    <Typography
                      variant="body1"
                      sx={{ mt: 2 }}
                      // fontWeight={700}
                      color="text.secondary"
                      component="div"
                    >
                      {template.description}
                    </Typography>
                    // <Box ml="auto">
                    //   <GradientButton
                    //     iconButton
                    //     tooltip={{
                    //       title: template.description,
                    //       placement: "right",
                    //     }}
                    //     color="text.secondary"
                    //     size="small"
                    //   >
                    //     <InfoRoundedIcon fontSize="small" />
                    //   </GradientButton>
                    // </Box>
                  )}
                </Stack>

                {/* <Typography variant="body2" color="text.secondary">
                  {template.description}
                </Typography> */}
              </CardContent>
              <CardActions disableSpacing>
                <Radio
                  checked={formData?.templateId?.value === template.id}
                  onChange={onRadioChange}
                  value={template.id}
                  name="template-selector"
                  inputProps={{ "aria-label": "Template Selector" }}
                />
              </CardActions>
            </CardActionArea>
          </Card>
          <Box position="absolute" right={10} bottom={10}>
            <GradientButton
              tooltip={{ title: "Preview" }}
              iconButton
              aria-label="view template"
              sx={{ ml: "auto" }}
              color="primary"
              component="a"
              target="_blank"
              href={template.previewUrl}
              size="small"
            >
              <OpenInNewRoundedIcon />
            </GradientButton>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

const WebsiteConfiguratorTemplateQuery = ({ setTemplates, ...props }) => {
  return (
    <QueryLoading
      query={agencyQueries.getTemplates}
      listKey="websiteTemplates"
      listEmpty={
        <>
          <strong>We are hard at work on the Templates</strong>
          <br /> They will be ready soon!
        </>
      }
      smallAlert
      listTitle="Template"
      Render={WebsiteConfiguratorTemplateDisplay}
      renderProps={props}
      setData={setTemplates}
    />
  );
};

export default WebsiteConfiguratorTemplateQuery;
