// Dashboard Pages
import WebsiteManager from "../components/dashboard/agency_manager/WebsiteManager";
import DashboardHome from "../components/dashboard/Home";
import Profile from "../components/dashboard/Profile";
import Calender from "../components/dashboard/Calender";
import LeadGeneration from "../components/dashboard/LeadGeneration";
import ClientManager from "../components/dashboard/client_manager/index";
import AgencyManagerHome from "../components/dashboard/agency_manager";
import PortfolioManager from "../components/dashboard/agency_manager/PortfolioManager";
// icons
import DataUsageRoundedIcon from "@mui/icons-material/DataUsageRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import GolfCourseRoundedIcon from "@mui/icons-material/GolfCourseRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import DataThresholdingRoundedIcon from "@mui/icons-material/DataThresholdingRounded";
// import TemplatePreviewManager from "../components/agency_templates/TemplatePreviewManager";
const basePath = "/dashboard/";
export const paths = {
  swaCourse: `https://www.marketceptionacademy.com/`,
  // Agency Paths
  agency: {
    index: `${basePath}agency/`,
    website: `${basePath}agency/website-manager/`,
    templatePreviewManager: `${basePath}agency/website-manager/:websiteId/preview`,
    portfolio: `${basePath}agency/portfolio/`,
  },
  // Client Paths
  client: {
    index: `${basePath}client/`,
  },
  leads: `${basePath}leads/`,
  // Account Paths
  calender: `${basePath}calender/`,
  profile: `${basePath}my-account/`,
  templatePreviewManager: `${basePath}/`,
};
export const agencyShortcuts = [
  { title: "Website", to: paths.agency.website, icon: LanguageRoundedIcon },
];

export const pages = [
  { component: DashboardHome, path: basePath },
  // Agency Paths
  { component: AgencyManagerHome, path: paths.agency.index },
  { component: WebsiteManager, path: paths.agency.website },
  { component: PortfolioManager, path: paths.agency.portfolio },
  { component: Calender, path: paths.calender },
  { component: ClientManager, path: paths.client.index },
  { component: Profile, path: paths.profile },
  { component: LeadGeneration, path: paths.leads },
  // {
  //   component: TemplatePreviewManager,
  //   path: paths.agency.templatePreviewManager,
  // },
  // { component: DashboardHome, path: basePath }
  // { component: DashboardHome, path: basePath }
  // { component: DashboardHome, path: basePath }
  // { component: DashboardHome, path: basePath }
];

export const navLinks = [
  // Personal
  {
    title: "Home",
    to: basePath,
    icon: HomeRoundedIcon,
  },
  {
    title: "My Agency",
    to: paths.agency.index,
    match: `${paths.agency.index}*`,
    sublinks: [
      { title: "Website", to: paths.agency.website, icon: LanguageRoundedIcon },
      {
        title: "Portfolio",
        to: paths.agency.portfolio,
        icon: DataThresholdingRoundedIcon,
      },
    ],
    icon: ApartmentRoundedIcon,
  },
  {
    title: "Calender", // Personal Calender - All other calenders combined
    to: paths.calender,
    icon: CalendarMonthRoundedIcon,
  },
  {
    title: "Leads", // Lead Generation System
    to: paths.leads,
    match: `${paths.leads}*`,
    icon: LeaderboardRoundedIcon,
  },
  {
    title: "Client Manager", // Client Manager - Specific to each client
    match: `${paths.client.index}*`,
    to: paths.client.index,
    sublinks: [
      { title: "Create a Client" },
      { title: "SEO" },
      { title: "Email Marketing" },
      {
        title: "Campaign Creation", //Ads
      },
      { title: "Analytics" },
      {
        title: "Marketing Calender",
      },
    ],
    icon: PeopleAltRoundedIcon,
  },
  {
    title: "SWA Course", // Link to the course
    to: paths.swaCourse,
    icon: GolfCourseRoundedIcon,
  },
  {
    title: "My Account", // Profile
    to: paths.profile,
    match: `${paths.profile}*`,
    icon: AccountCircleRoundedIcon,
  },
];
// Facebook -
// Google Features -
