import { Box } from "@mui/material";
import React from "react";
import FormField from "./FormField";
import ActionButtons from "./ActionButtons";
import {
  getSubmitVariables,
  isEmpty,
  isFormFieldValid,
  validateForm,
} from "../../utils/forms";
import { useId } from "react-id-generator";
import ModalDialogActions from "./ModalDialogActions";
import ModalDialogContent from "./ModalDialogContent";

const Wrap = ({ dismissModal, children, ...props }) =>
  dismissModal ? (
    <ModalDialogActions
      children={children}
      dismissModal={dismissModal}
      {...props}
    />
  ) : (
    children
  );

const FormMainRender = ({
  // buttonProps,
  isValid,
  children,
  loading,
  actions,
  setLoading,
  formProps,
  variables,
  resetForm,
  modalInnerRender,
}) => {
  const actionButtonProps = {
    setLoading: setLoading,
    actions: actions,
    loading: loading,
    resetForm: resetForm,
    disabled: !isValid || loading,
    variables: variables,
    isValid: isValid,
  };
  return actions ? (
    modalInnerRender ? (
      <ModalDialogContent
        {...modalInnerRender}
        formActionProps={actionButtonProps}
      >
        <Box {...formProps}>{children}</Box>
      </ModalDialogContent>
    ) : (
      <Box
        component="form"
        sx={{ width: "100%" }}
        onSubmit={(e) => e.preventDefault(e)}
      >
        <Box {...formProps}>
          {children}
          <ActionButtons {...actionButtonProps} />
        </Box>
      </Box>
    )
  ) : (
    <>{children}</>
  );
};

function Form({
  fields: fs,
  genFields,
  setFields: setFs,
  loading,
  setValid: setValidPass,
  // buttonProps,
  actions,
  setLoading,
  formFieldBoxProps,
  modalProps: modalInnerRender,
  ...formProps
}) {
  const genFieldFinal = () =>
    genFields &&
    Object.keys(genFields).reduce((obj, fieldKey) => {
      const f = genFields[fieldKey];
      obj[fieldKey] = { ...f, value: f?.value ?? "" };
      // console.log(f, obj[fieldKey]);
      return obj;
    }, {});
  const [fieldsF, setFieldsF] = React.useState(fs ?? genFieldFinal());
  const fields = setFs ? fs : fieldsF;
  const setFields = setFs ?? setFieldsF;
  const resetForm = () =>
    setFs
      ? genFields && setFields(genFieldFinal())
      : setFieldsF(fs ?? genFieldFinal());
  const [isValid, setIsValid] = React.useState(false);
  const setValid = setValidPass || setIsValid;

  const syncFields = (pass) => {
    const t = Object.keys(fields).filter(
      (x) => fields[x].requiredOr || fields[x].requiredDependent
    ); // Store all fieldswhich are dependent on others in -> t
    let fs = { ...fields, ...pass };
    const tAr = {}; // Store dependent values
    t.map((k) => {
      const obj = fs[k];
      const l =
        obj.requiredOr || obj.requiredDependent
          ? {
              requiredConditional: obj.requiredDependent
                ? !isEmpty(fs[obj.requiredDependent].value)
                : isEmpty(fs[obj.requiredOr].value),
            }
          : null;
      if (l)
        tAr[k] = {
          ...obj,
          ...(obj?.requiredConditional !== l.requiredConditional
            ? isFormFieldValid({ ...obj, ...l })?.update
            : {}),
          ...l,
        };
      return null;
    });
    fs = { ...fs, ...tAr }; // Combine dependent with fields    // setValid(validateForm(fs));
    setFields(fs);
  };
  const updateField = (key, data) =>
    syncFields({ [key]: { ...fields[key], ...data } });

  React.useEffect(() => {
    syncFields();
  }, []);
  React.useEffect(() => {
    setValid(validateForm(fields));
  }, [fields]);
  const [id] = useId();
  return (
    <FormMainRender
      actions={actions}
      isValid={isValid}
      loading={loading}
      setLoading={setLoading}
      formProps={formProps}
      resetForm={resetForm}
      modalInnerRender={modalInnerRender}
      variables={() => getSubmitVariables(fields)}
    >
      {Object.keys(fields).map(
        (item, i) =>
          !fields[item]?.hide && (
            <Box
              key={i}
              {...(fields[item]?.boxProps || formFieldBoxProps)}
              sx={{
                mb: 4,
                width: "100%",
                ...(fields[item]?.sx ?? {}),
              }}
            >
              <FormField
                loading={fields[item].loading || loading}
                updateField={updateField}
                name={item}
                {...fields[item]}
              />
            </Box>
          )
      )}
    </FormMainRender>
  );
}

export default Form;
