import React from "react";
import { DialogContent, DialogContentText, Box } from "@mui/material";
import ModalDialogActions from "./ModalDialogActions";
import ActionButtons from "./ActionButtons";

const ModalDialogContent = ({
    contentText,
    children,
    dismissModal,
    render: Render,
    renderProps,
    formActionProps,
}) => {
    return (
        <>
            <DialogContent>
                <Box sx={{ pt: 4 }}>
                    {contentText && <DialogContentText>{contentText}</DialogContentText>}
                </Box>
                {Render ? <Render {...renderProps} /> : children ?? <></>}
            </DialogContent>
            <ModalDialogActions dismissModal={dismissModal}>
                {formActionProps && (
                    <ActionButtons closeModal={dismissModal} {...formActionProps} />
                )}
            </ModalDialogActions>
        </>
    );
};

export default ModalDialogContent;