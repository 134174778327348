import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import GradientButton from "./GradientButton";
import { IconButton, Dialog, DialogTitle } from "@mui/material";
import Form from "./Form";
import ModalDialogContent from "./ModalDialogContent";

const ButtonMain = ({
  boxProps,
  children,
  modal,
  mutation,
  ButtonRender,
  ...buttonProps
}) => {
  // mdoal = {title, actions,Render, renderProps}
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {ButtonRender ? (
        <ButtonRender onClick={handleClickOpen} {...buttonProps}>
          {children}
        </ButtonRender>
      ) : (
        <GradientButton
          variant="contained"
          onClick={handleClickOpen}
          boxProps={boxProps}
          type="button"
          {...buttonProps}
        >
          {children}
        </GradientButton>
      )}
      {modal && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby={modal.label ?? "button-modal"}
          fullWidth={true}
          scroll="body"
          maxWidth="md"
          PaperProps={{ component: modal.form ? "form" : "div" }}
          {...modal.dialogProps}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              py: 1,
              boxShadow: 2,
              borderBottom: "1px solid #eee",
            }}
          >
            {modal.title}
            <IconButton
              sx={{ ml: "auto", float: "right" }}
              aria-label="close"
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          {modal.form ? (
            <Form
              modalProps={{
                dismissModal: handleClose,
                contentText: modal.contentText,
              }}
              // dismissModal={handleClose}
              {...modal.renderProps}
            />
          ) : (
            <ModalDialogContent
              dismissModal={handleClose}
              contentText={modal.contentText}
              render={modal.Render}
              renderProps={modal.renderProps}
            />
          )}
        </Dialog>
      )}
    </>
  );
};

export default ButtonMain;
