import React from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Chip,
  Checkbox,
  Autocomplete,
  TextField,
  CircularProgress,
  Tooltip,
  Box,
} from "@mui/material";
import { useStateValue } from "../../../../StateProvider";
import { actionTypes } from "../../../../reducer";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import agencyQueries from "../../../../queries/agency";
import MutationLoading from "../../../gql/MutationLoading";

const ServiceInput = ({
  servicesQueryLoading: queryLoading,
  servicesData,
  servicesRefetch: refetch,
}) => {
  const [inputValue, setInputValue] = React.useState(""); // controls typed value
  const [{ user }, dispatch] = useStateValue();
  const agencyServices = user?.agency?.servicesOffered;
  // We pass agencies existing services stored in agency.services
  const [value, setValue] = React.useState([...agencyServices]); // controls stored option
  const { enqueueSnackbar } = useSnackbar();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [addNewWebsiteService, { loading: mutLoading, error }] = useMutation(
    agencyQueries.createWebsiteService
  );
  const loading = mutLoading || queryLoading;
  const updateSearchResults = (newInputValue) =>
    refetch({ name: newInputValue });
  const addNewValue = ({ inputValue }) => {
    //Key = service typed
    // props - contains data from autocomplete
    // Adds new services to database
    addNewWebsiteService({ variables: { name: inputValue } })
      .then(({ data }) => {
        const service = data.createWebsiteService?.service;
        if (service == null) {
          enqueueSnackbar(`An error occurred while creating a service`, {
            variant: "error",
          });
        } else {
          setValue([...value, service]);
          //   props.onClick(event);
          refetch();
          // we are pulling data from the user agency so we add values there
          // updateQs(service.id, service, false); // Add to all service list
        }
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(`An internal error occurred while creating a service`, {
          variant: "error",
        });
      });
    // Update queryset after mutation
  };

  const ChipTag = (getTagProps) => (option, index) => {
    const disabled = !!agencyServices.find(
      (service) => option.id === service.id
    );
    const tagProps = getTagProps({ index });
    //   disab l e d={disab l;ed}
    const c = <Chip label={option.name} {...tagProps} disabled={disabled} />;
    if (disabled) {
      return (
        <Tooltip
          key={tagProps.key}
          arrow
          title="Service is assigned to portfolio"
        >
          <span style={{ cursor: "pointer" }}>{c}</span>
        </Tooltip>
      );
    }
    return c;
  };
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      alignItems="center"
      //   alignItems="flex-start"
    >
      <Box flex={1} mb={1}>
        <Autocomplete
          multiple
          id="services-offered"
          //   defaultValues={agencyServices}
          options={servicesData?.services ?? []}
          // getOptionLabel={(option) => option.name}
          loading={loading}
          // limitTags={4}
          value={value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            //   const popped = newValue.pop();
            const last = newValue.at(-1);
            if (last && last.name.includes("Add")) {
              // add to database
              addNewValue({ inputValue: last.inputValue });
            } else {
              setValue(newValue);
            }
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            // todo configure search results if needed
            // updateSearchResults(newInputValue);
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            // return option.name;
            if (typeof option === "string") {
              return option;
            }

            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.name
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          //   renderTags={(tagValue, getTagProps) => tagValue.map(ChipTag(getTagProps))}
          // defaultValue={}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              {!option.name.includes("Add") && (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              )}
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              label="Services"
              helperText="Values are only overwritten when update button is clicked, Refresh to reset"
              // placeholder="Favorites"
            />
          )}
          // renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </Box>
      <Box pl={3} ml="auto">
        <MutationLoading
          dataKey="assignServicesToAgency"
          query={agencyQueries.assignServicesToAgency}
          variables={() => ({ serviceIds: value.map((o) => o.id) })}
          updateValue={({ agency }) => {
            if (!agency?.servicesOffered) {
              enqueueSnackbar("An error occurred while trying to update", {
                variant: "error",
              });
              return null;
            }
            dispatch({
              type: actionTypes.SET_USER_AGENCY,
              agency: {
                ...user.agency,
                servicesOffered: agency.servicesOffered,
              },
            });
            // agency.servicesOffered; The only returned list from mutation
            setValue(agency.servicesOffered);
          }}
          successMessage="Updated Successfully"
          // ignoreSuccessMessage
          buttonProps={{
            //   tooltip: { title: "Delete", placement: "bottom" },
            //   iconButton: true,
            color: "primary",
            children: "Update",
            size: "small",
          }}
        />
      </Box>
    </Box>
  );
};

export default ServiceInput;
