import React from "react";
// import "./ContainerLoader.css";
import { Box, CircularProgress } from "@mui/material";
import { css } from "@emotion/react"

function ContainerLoader({ loading, relative, children, ...props }) {
    const containerLoaderC = css`
            position:absolute;
            top:0;
            left:0;
            height:100%;
            width:100%;
            display:flex;
            align-items: center;
            justify-content: center;
            background-color:rgba(255,255,255,.5);
            z-index:100;
            opacity:1;
            visibility: visible;
            transition:all .3s;
        `
    const cOut = css`opacity:0;
                visibility:hidden`
    return (
        <>
            <Box
                className={`${!relative ? containerLoaderC : ""} ${loading ? "" : cOut}`}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 3,
                }}
            >
                {loading === true ? (
                    <CircularProgress
                        style={relative && { height: "100%", width: "100%" }}
                    />
                ) : (
                    <React.Fragment />
                )}
            </Box>
            {children && !loading ? (
                <Box {...props}>{children}</Box>
            ) : (
                <React.Fragment />
            )}
        </>
    );
}

export default ContainerLoader;