import React from "react";
import { Paper, Typography, Stack, Box } from "@mui/material";

const Section = ({
  children,
  title,
  subtitle,
  flat,
  titleBefore,
  titleEnd,
  sx = {},
  ...paperProps
}) => {
  const s = flat
    ? { py: 3 }
    : { backgroundColor: "rgb(255,255,255)", borderRadius: 3, p: 3 };
  return (
    <Paper
      component="section"
      elevation={flat ? 0 : 2}
      sx={{
        ...s,
        position: "relative",
        mb: 3,
        ...sx,
      }}
      {...paperProps}
    >
      {(title || titleBefore || titleEnd || subtitle) && (
        <Stack
          alignItems="start"
          direction={{ xs: "column", sm: "row" }}
          sx={{ pb: 2 }}
        >
          {titleBefore && titleBefore}
          <Box sx={{ pr: titleEnd ? 2 : 0, pl: titleBefore ? 2 : 0 }}>
            {title && (
              <Typography variant="h6" component="h6" fontWeight={800}>
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography variant="body2" sx={{ pb: 2 }}>
                {subtitle}
              </Typography>
            )}
          </Box>
          {titleEnd && titleEnd}
        </Stack>
      )}

      {children}
    </Paper>
  );
};

export default Section;
