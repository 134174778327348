import React from "react";
import { useStateValue } from "../../../StateProvider";
import Section from "../Section";
import QueryLoading from "../../gql/QueryLoading";
import MutationLoading from "../../gql/MutationLoading";
import agencyQueries from "../../../queries/agency";
import { useSnackbar } from "notistack";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  CardMedia,
  Box,
} from "@mui/material";
import Button from "../../forms/Button";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import Image from "../../layout/ImageDisplay";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ServiceInput from "./portfolio_manager/ServiceInput";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { paths } from "../../../utils/dashboardConstants";
const PortfolioListItem = ({ updateQs, portfolio, servicesData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [completedDate, setCompletedDate] = React.useState();
  React.useEffect(() => {
    setCompletedDate(
      portfolio.dateCompleted
        ? dayjs(portfolio.dateCompleted).format("D MMM YYYY")
        : null
    );
  }, [portfolio?.dateCompleted]);
  return (
    <Grid item lg={3} md={4} xs={6} sx={{ position: "relative" }}>
      <Card sx={{ borderRadius: 2, height: "100%" }}>
        {(portfolio.thumbnailUrl || portfolio.previewImageUrl) && (
          <CardMedia>
            <Image
              src={portfolio.thumbnailUrl ?? portfolio.previewImageUrl}
              alt={portfolio.title}
            />
          </CardMedia>
        )}
        <CardContent>
          <Typography variant="h6" fontWeight={700} component="div">
            {portfolio.title}
          </Typography>
          {portfolio.portfolioType && (
            <Typography
              sx={{ py: 1 }}
              variant="body2"
              color="text.secondary"
              fontWeight={700}
            >
              {portfolio.portfolioType.name}
            </Typography>
          )}
          {completedDate && (
            <Typography
              sx={{ py: 1 }}
              variant="body2"
              color="text.secondary"
              fontWeight={700}
            >
              {completedDate}
            </Typography>
          )}

          <Typography variant="body2" color="text.secondary">
            {portfolio.description}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          {portfolio.link && (
            <Button
              iconButton
              tooltip={{ title: "View" }}
              aria-label="view template"
              sx={{ ml: "auto" }}
              color="primary"
              component="a"
              target="_blank"
              href={portfolio.link}
              size="small"
            >
              <OpenInNewRoundedIcon fontSize="small" />
            </Button>
          )}
          {/* <Switch defaultChecked /> */}
          <Box ml={2}>
            <UpdatePortfolio
              portfolio={portfolio}
              iconButton
              servicesData={servicesData}
              updatePortfolios={updateQs}
            />
          </Box>
          <Box ml="auto">
            <MutationLoading
              dataKey="deleteAgencyPortfolio"
              query={agencyQueries.deleteAgencyPortfolio}
              variables={{ portfolioId: portfolio.id }}
              updateValue={({ deleted }) => {
                if (!deleted) {
                  enqueueSnackbar(
                    "An error occurred while trying to delete your portfolio",
                    {
                      variant: "error",
                    }
                  );
                  return null;
                }
                updateQs(
                  portfolio.id,
                  null,
                  // domain,
                  true
                );
              }}
              successMessage="Deleted Successfully"
              // ignoreSuccessMessage
              buttonProps={{
                tooltip: { title: "Delete", placement: "bottom" },
                iconButton: true,
                color: "error",
                children: <DeleteRoundedIcon />,
                size: "small",
              }}
            />
          </Box>
          {/* <Button size="small">Configure</Button>
              <Button size="small">Edit</Button> */}
        </CardActions>
      </Card>
    </Grid>
  );
};

const PortfolioList = ({ data: portfolios, updateQs, servicesData }) => {
  return (
    <Grid container spacing={2} justifyContent="center">
      {portfolios.map((portfolio) => (
        <PortfolioListItem
          portfolio={portfolio}
          updateQs={updateQs}
          servicesData={servicesData}
          key={portfolio.id}
        />
      ))}
    </Grid>
  );
};

const UpdatePortfolio = ({
  portfolio,
  iconButton,
  updatePortfolios,
  servicesData,
}) => {
  // No portfolio - create else update
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Button
      iconButton={iconButton}
      tooltip={iconButton && { title: "Edit", placement: "bottom" }}
      size="small"
      color={iconButton && "primary"}
      modal={{
        label: portfolio
          ? `update-portfolio-${portfolio.id}`
          : "create-website",
        title: portfolio ? "Update Portfolio" : "Create Portfolio",
        dialogProps: {
          scroll: "paper",
          fullWidth: true,
          maxWidth: "sm",
        },
        form: true,
        renderProps: {
          genFields: {
            ...(portfolio
              ? { portfolioId: { value: portfolio.id, hide: true } }
              : {}),
            portfolioType: {
              type: "autocomplete",
              label: "Portfolio Type",
              value: portfolio?.portfolioType,
              options: servicesData ?? [],
            },
            title: {
              value: portfolio?.title ?? "",
              label: "TItle",
              required: true,
              helperText: "A snappy title to describe this piece of work",
            },
            description: {
              value: portfolio?.description ?? "",
              label: "Description",
              required: false,
              multiline: true,
              helperText:
                "A more detailed account, shown when clicked or hover",
            },
            shortDescription: {
              value: portfolio?.shortDescription,
              label: "Short Description",
              multiline: true,
              helperText:
                "A shorter description where needed, defaults to description and truncated if necessary",
            },
            // listOrder,
            dateCompleted: {
              type: "date",
              value: portfolio?.dateCompleted && dayjs(portfolio.dateCompleted),
              help_text: "Date this project was completed",
              required: true,
              label: "Date Completed",
            },
            link: {
              label: "Link",
              value: portfolio?.link,
              helperText:
                "A preview link for this piece of work, (Absolute url)",
            },
            displayOnWebsite: {
              type: "checkbox",
              value: portfolio?.displayOnWebsite ?? true,
              label: "Display on Website",
              helperText:
                "Determines wether this will be displayed on your websites",
            },
            previewImage: {
              required: false,
              type: "file",
              label: "Preview Image",
              accept: ".png, .jpg, .jpeg",
              helperText: "The main image to showcase your work",
              displayPreview: portfolio?.previewImageUrl
                ? {
                    url: portfolio.previewImageUrl,
                    name: portfolio.previewImageName,
                  }
                : null,
            },
            thumbnail: {
              required: false,
              type: "file",
              label: "Thumbnail Image",
              accept: ".png, .jpg, .jpeg",
              helperText:
                "A smaller image to show in list views (Leave blank to use preview image)",
              displayPreview: portfolio?.thumbnailUrl
                ? {
                    url: portfolio.thumbnailUrl,
                    name: portfolio.thumbnailName,
                  }
                : null,
            },
          },
          actions: [
            {
              mutation: {
                dataKey: "updateAgencyPortfolio",
                query: agencyQueries.updateAgencyPortfolio,
                resetOnSubmit: !portfolio,
                successMessage: portfolio
                  ? "Portfolio updated successfully"
                  : "Portfolio created successfully",
                buttonProps: { children: portfolio ? "Update" : "Create" },
                updateValue: ({ portfolio: portfolioNew }) => {
                  if (portfolioNew == null) {
                    enqueueSnackbar(
                      `An error occurred while ${
                        portfolio ? "updating" : "creating"
                      } your portfolio`,
                      {
                        variant: "error",
                      }
                    );
                    return null;
                  }
                  updatePortfolios &&
                    updatePortfolios(portfolioNew.id, portfolioNew, false);
                },
              },
            },
          ],
        },
      }}
    >
      {iconButton ? <CreateRoundedIcon /> : portfolio ? "Update" : "Create"}
    </Button>
  );
};

const PortfolioManager = () => {
  const [{ user }] = useStateValue();
  React.useEffect(() => {
    if (!user.agency) {
      navigate(paths.agency.index);
    }
  }, []);
  if (!user.agency) {
    return <></>;
  }
  return <PortfolioManagerInner />;
};

const PortfolioManagerInner = () => {
  const [{ user }] = useStateValue();
  const [updatePortfolios, setUpdatePortfolios] = React.useState(null);
  const {
    data: servicesDataD,
    loading: servicesQueryLoading,
    refetch: servicesRefetch,
  } = useQuery(agencyQueries.getServices);
  const servicesData = servicesDataD?.services;
  return (
    <>
      <Section title="Services your Offer">
        {/* <QueryLoading
          query={agencyQueries.getAgencyServices}
          listKey="agencyServices"
          Render={ServiceChips}
          smallAlert
          listTitle="Website Services"
          forceRender
        /> */}
        <ServiceInput
          servicesQueryLoading={servicesQueryLoading}
          servicesData={servicesData}
          servicesRefetch={servicesRefetch}
        />
      </Section>
      <Section
        title={`${user.agency.name} Portfolio`}
        titleEnd={
          <Box ml="auto">
            <UpdatePortfolio
              servicesData={servicesData}
              updatePortfolios={updatePortfolios}
            />
          </Box>
        }
      ></Section>
      <QueryLoading
        query={agencyQueries.getPortfolios}
        listKey="portfolios"
        Render={PortfolioList}
        renderProps={{ servicesData }}
        setUpdateQs={setUpdatePortfolios}
        smallAlert
        listTitle="Portfolio"
        // forceRender
      />
    </>
  );
};

export default PortfolioManager;
