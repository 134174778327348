import React from "react";
import TextField from "./TextField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Autocomplete,
  TextField as MuiTextField,
  CircularProgress,
} from "@mui/material";
import Button from "./Button";
import { useId } from "react-id-generator";
import { MuiColorInput } from "mui-color-input";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";

const SelectHelperDisplay = ({
  helperText,
  error,
  helperTextComponent,
  helperTextComponentProps,
}) => {
  if (!helperText || !error) return <></>;
  return (
    <FormHelperText error={!!error}>
      {error ? (
        error
      ) : helperTextComponent ? (
        <helperTextComponent {...helperTextComponentProps} />
      ) : (
        helperText
      )}
      {}
    </FormHelperText>
  );
};

function FormField({
  updateField,
  type,
  label,
  error,
  value,
  readOnly,
  multiline,
  required: requiredPass,
  loading,
  int,
  options,
  disabled,
  helperTrue,
  helperFalse,
  helperText,
  helperTextComponent,
  helperTextComponentProps,
  name,
  setValue,
  requiredDependent,
  requiredOr,
  requiredConditional,
  ...fieldProps
}) {
  const [id] = useId();
  const required = !!(requiredConditional ?? requiredPass);
  const updateParentField = (data) => {
    setValue ? setValue(data) : updateField(name, data);
  };

  const updateValue = (data) => {
    setValue ? setValue(data) : updateField(name, { value: data });
  };
  // if (type == "hidden") {
  //   return <input value={value} type="hidden" name={name} />
  // }
  const [inputValue, setInputValue] = React.useState("");
  if (type === "autocomplete") {
    return (
      <Autocomplete
        loading={loading}
        options={[...options]}
        required={required}
        readOnly={readOnly}
        id={id}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label={label}
            helperText={helperText}
            // placeholder="Favorites"
          />
        )}
        getOptionLabel={(option) =>
          option.name ?? option.title ?? option.inputValue
        }
        value={value}
        onChange={(event, newValue) => updateValue(newValue)}
        {...fieldProps}
      />
    );
  } else if (type === "select") {
    return (
      <FormControl
        required={required}
        fullWidth
        disabled={loading ? loading : disabled}
      >
        <InputLabel id={`select-label-${id}`}>{label}</InputLabel>
        <Select
          labelId={`select-label-${id}`}
          id={`select-${id}`}
          label={label}
          value={value}
          error={!!error}
          onChange={(event) => updateValue(event.target.value)}
        >
          {options.map((o) => (
            <MenuItem value={o.value} key={o.value}>
              {o.text ? o.text : o.value}
            </MenuItem>
          ))}
        </Select>
        <SelectHelperDisplay
          error={error}
          helperText={helperText}
          helperTextComponent={helperTextComponent}
          helperTextComponentProps={helperTextComponentProps}
        />
      </FormControl>
    );
  } else if (type === "date" || type === "datetime" || type === "time") {
    const D =
      type === "date"
        ? DatePicker
        : type === "datetime"
        ? DateTimePicker
        : TimePicker;
    return (
      <D
        label={label}
        value={value}
        onChange={(newValue) => updateValue(newValue)}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        slotProps={{
          textField: {
            helperText: helperText,
          },
        }}
        {...fieldProps}
      />
    );
  } else if (type == "color") {
    return (
      <MuiColorInput
        value={value}
        required={required}
        fullWidth
        helperText={helperText}
        label={label}
        disabled={loading ? loading : disabled}
        onChange={(color) => updateValue(color)}
      />
    );
  } else if (type === "checkbox") {
    return (
      <FormControl
        required={required}
        error={!!error}
        component="fieldset"
        fullWidth
        disabled={readOnly}
      >
        {/* <FormLabel component="legend">Pick two</FormLabel> */}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) => updateValue(e.target.checked)}
                name={name}
                size={fieldProps.size}
              />
            }
            label={label}
          />
        </FormGroup>
        <FormHelperText>
          {value ? helperTrue : helperFalse ?? ""}
        </FormHelperText>
      </FormControl>
    );
  } else if (type === "file") {
    return (
      <>
        {label && (
          <Typography
            component="label"
            sx={{ pb: 1, fontWeight: 700, display: "block" }}
            htmlFor={id}
          >
            {label}
            {required && (
              <Typography
                component="span"
                sx={{ pl: 1 }}
                variant="small"
                color="error"
              >
                *
              </Typography>
            )}
          </Typography>
        )}
        {!value && fieldProps?.displayPreview?.url && (
          <Box
            sx={{ maxHeight: 75, mr: 2 }}
            component="img"
            src={fieldProps.displayPreview.url}
          />
        )}
        <Box
          sx={{ display: "flex", alignItems: "center", mb: helperText ? 1 : 0 }}
        >
          <label htmlFor={id}>
            <input
              id={id}
              type="file"
              accept={fieldProps.accept}
              name={name}
              required={required}
              style={{ display: "none" }}
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  updateValue(file);
                }
              }}
            />
            <Button
              boxProps={{ component: "span" }}
              className="btn-choose"
              size="small"
              component="span"
              disabled={loading}
            >
              {`${value ? "Change" : "Choose"} Files`}
            </Button>
          </label>
          <Typography
            sx={{
              maxWidth: "100%",
              border: 1,
              borderLeftWidth: 0,
              borderColor: "grey.500",
              textOverflow: "ellipsis",
              overflow: "hidden",
              borderRadius: 1,
              whiteSpace: "no-wrap",
              fontSize: 14,
              px: 2,
              py: 1,
            }}
          >
            {value?.name ??
              fieldProps?.displayPreview?.name ??
              (!value && "Please select a File")}
          </Typography>
        </Box>
        <Typography variant="small" color="text.secondary" component="small">
          {helperText}
        </Typography>
      </>
    );
  }
  return (
    <TextField
      error={error}
      // updateError={updateError}
      value={value}
      // updateValue={updateValue}
      updateField={updateParentField}
      loading={readOnly ? readOnly : loading}
      label={label}
      readOnly={readOnly}
      required={required}
      multiline={multiline}
      integer={int}
      id={id}
      type={type}
      helperText={helperText}
      helperTextComponent={helperTextComponent}
      helperTextComponentProps={helperTextComponentProps}
      {...fieldProps}
    />
  );
}

export default FormField;
