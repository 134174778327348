import { Box, Grid, IconButton, Typography, Container } from "@mui/material";
import React from "react";
import LoginGraphic from "./LoginGraphic";
// import LoginParent from "../components/auth/LoginParent";
// import {
//     Routes,
//     Route,
//     Navigate,
// } from "react-router-dom";
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import LoginForm from "./LoginForm";
// import { useReducedMotion, motion, AnimatePresence } from "framer-motion";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ForgotPassword from "./ForgotPassword";
import { TransitionGroup } from 'react-transition-group';
import Slide from '@mui/material/Slide';

function Login() {
    const [email, setEmail] = React.useState();
    const { pathname } = useLocation();
    const containerRef = React.useRef(null);
    const basePath = "/dashboard"
    return (
        <Box
            sx={{
                bgcolor: "primary.dark",
                minHeight: "100vh",
                width: "100%",
            }}
        >
            <Container maxWidth="lg" fixed>
                <Box sx={{ pt: 3 }}>
                    {/* <HoiResponsive portal /> */}
                </Box>
                <Grid
                    container
                    sx={{
                        boxShadow: 3,
                        overflowX: "hidden",
                        bgcolor: "#fff",
                        borderRadius: 3,
                        // minHeight: 500,
                        mt: 3,
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                            display: "flex",
                            p: 3,
                            minHeight: { xs: 400, lg: "auto" },
                            transition: "height .3s",
                        }}
                        ref={containerRef}
                    >
                        {/* <TransitionGroup> */}
                        <Slide container={containerRef.current} direction="right" in={pathname === `${basePath}/login/`} mountOnEnter unmountOnExit>
                            <Box>
                                <Typography variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
                                    Sign In
                                </Typography>
                                <Typography color="text.secondary" sx={{ mb: 3 }}>
                                    The Resource Platform For Financial Freedom
                                </Typography>
                                <LoginForm setEmail={setEmail} basePath={basePath} />
                            </Box>
                        </Slide>
                        <Slide container={containerRef.current} direction="left" in={pathname === `${basePath}/login/forgot-password`} mountOnEnter unmountOnExit>
                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        alignContent: "center",
                                        mb: 3,
                                    }}
                                >
                                    <IconButton
                                        size="large"
                                        // component={Link}
                                        // to="/login/"
                                        onClick={() => pathname != `${basePath}/login/` && navigate(`${basePath}/login/`)}
                                        sx={{ ml: -2, mr: 1 }}
                                    >
                                        <ArrowBackRoundedIcon />
                                    </IconButton>
                                    <Typography variant="h6">Forgotten Password</Typography>
                                </Box>
                                <ForgotPassword email={email} basePath={basePath} />
                            </Box>
                        </Slide>
                        {/* </TransitionGroup> */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {/* <LoginGraphic /> */}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Login;