import React from "react";
import GradientButton from "../../../forms/GradientButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  Box,
  Stack,
  InputAdornment,
} from "@mui/material";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import CycloneRoundedIcon from "@mui/icons-material/CycloneRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import WebsiteConfiguratorStepper from "./WebsiteConfiguratorStepper";
import WebsiteConfiguratorTemplateQuery from "./WebsiteConfiguratorTemplate";
import WebsiteConfiguratorPlanQuery from "./WebsiteConfiguratorPlan";
import FormField from "../../../forms/FormField";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidRoundedIcon from "@mui/icons-material/PhoneAndroidRounded";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { getSubmitVariables, validateForm } from "../../../../utils/forms";
import GoogleIcon from "@mui/icons-material/Google";
import { useStateValue } from "../../../../StateProvider";
import { gql, useMutation } from "@apollo/client";
import agencyQueries from "../../../../queries/agency";
import { useSnackbar } from "notistack";

const stepIcons = {
  1: <ArticleRoundedIcon />,
  2: <CycloneRoundedIcon />,
  3: <TuneRoundedIcon />,
  4: <AssignmentTurnedInRoundedIcon />,
};
const steps = ["Template", "Plan", "Customize", "Finalize"];
const nextDisabledTooltips = [
  "Select a Template",
  "Choose a Plan",
  "Please fill in all required fields",
  "Please fill in all required fields",
];
const modalDescriptions = [
  "Start by choosing your Template!",
  "Choose a plan that suits you!",
  "Customize your site to suit your needs, for more customization please contact us after creating your site",
  "Fine tune your website",
];
const customizeFields = [
  "brandDisplayName",
  "pageTitle",
  "pageSlogan",
  "aboutUs",
  "portfolioDescription",
  "servicesDescription",
  "instagram",
  "facebookPage",
  "tiktok",
  "twitter",
  "linkedin",
  "contactEmail",
  "contactNumber",
];
const finalizeFields = [
  "name",
  "domainId",
  // "subdomain",
  // "subUrl",
  "facebookPixelId",
  "tiktokPixelId",
  "googleAnalyticsId",
  "gtagId",
];
const WebsiteConfigurator = ({
  website,
  domains,
  updateActiveWebsite,
  buttonBoxProps,
  buttonProps,
  children,
}) => {
  const [{ user }] = useStateValue();
  const [updateWebsiteMutation, { data, loading, error }] = useMutation(
    agencyQueries.updateWebsite
  );
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [plans, setPlans] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  // todo set update website paramters
  const [formData, setFormData] = React.useState({
    websiteId: { value: website?.id },
    planId: { value: website?.planSubscription.plan.id },
    templateId: { value: website?.template?.id },
    domainId: {
      type: "select",
      value: website?.domain?.id,
      label: "Domain",
      // required: true,
      options: [],
      helperText:
        "If your domain isn't shown please check your plan and make sure the domain has been verified",
    },
    // { name: "servicesOFFERED", label: "Page Slogan" },
    //TODO integrate services offered using  multi select with chips
    //Customize Fields
    brandDisplayName: {
      label: "Agency Display Name",
      helperText: "This is how we will show your agency name",
      required: true,
      beforeTitle: "Display Settings",
      value: website?.brandDisplayName,
    },
    pageTitle: {
      label: "Page Title",
      helperText: "The main heading on the Home Page",
      required: true,
      value: website?.pageTitle,
    },
    pageSlogan: {
      label: "Page Slogan",
      helperText: "The main slogan for your website",
      required: true,
      value: website?.pageSlogan,
    },
    aboutUs: {
      label: "About Us",
      helperText: "The About us text",
      required: true,
      multiline: true,
      boxProps: { mb: 3 },
      value: website?.aboutUs,
    },
    portfolioDescription: {
      label: "Portfolio Description",
      helperText: "Some extra descriptive text (Not required)",
      required: false,
      multiline: true,
      boxProps: { mb: 3 },
      value: website?.portfolioDescription,
    },
    servicesDescription: {
      label: "Services Description",
      helperText: "Some extra descriptive text (Not required)",
      required: false,
      multiline: true,
      boxProps: { mb: 3 },
      value: website?.servicesDescription,
    },
    instagram: {
      label: "Instagram",
      beforeTitle: "Social Media & Contact",
      icon: InstagramIcon,
      value: website?.instagram,
      helperText: "Username or absolute url, and we will handle the rest",
    },
    facebookPage: {
      label: "Facebook Page",
      icon: FacebookIcon,
      value: website?.facebookPage,
      helperText: "Page ID, Username or absolute URL",
    },
    tiktok: {
      label: "TikTok",
      icon: MusicNoteIcon,
      value: website?.tiktok,
      helperText: "Username or absolute URL",
    },
    twitter: {
      label: "Twitter",
      icon: TwitterIcon,
      value: website?.twitter,
      helperText: "Username or absolute URL",
    },
    linkedin: {
      label: "LinkedIn",
      icon: LinkedInIcon,
      value: website?.linkedin,
      helperText: "Username or absolute URL",
    },
    contactEmail: {
      label: "Contact Email",
      icon: EmailIcon,
      type: "email",
      value: website?.contactEmail,
    },
    contactNumber: {
      type: "tel",
      label: "Contact Number",
      icon: PhoneAndroidRoundedIcon,
      value: website?.contactNumber,
      helperText: "Including country code",
    },
    // Finalize Fields
    name: {
      label: "Nickname",
      value: website?.name,
      helperText:
        "An internal nickname for this site (We will generate one for you if empty)",
    },
    // subdomain: {
    //   value: website?.subdomain ?? "",
    //   label: "Sub-Domain",
    //   required: false,
    //   helperText: "For hosting on mysite.example.com",
    // },
    // subUrl: {
    //   value: website?.subdomain ?? "",
    //   label: "Sub Url",
    //   required: false,
    //   helperText: "For hosting on example.com/mysite",
    // },
    facebookPixelId: {
      label: "Facebook Pixel ID",
      icon: FacebookIcon,
      value: website?.facebookPixelId,
    },
    tiktokPixelId: {
      label: "TikTok Pixel ID",
      icon: MusicNoteIcon,
      value: website?.tiktokPixelId,
    },
    googleAnalyticsId: {
      label: "Google Analytics ID",
      icon: GoogleIcon,
      value: website?.googleAnalyticsId,
    },
    gtagId: { label: "GTAG ID", icon: GoogleIcon, value: website?.gtagId },
  });
  const [templates, setTemplates] = React.useState(null);

  const [nextDisabled, setNextDisabled] = React.useState(false);
  const stepLength = steps.length;
  const borderColor = "#eee";
  const nextLoading =
    (activeStep === 0 && templates === null) ||
    (activeStep === 1 && plans === null);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const updateFormData = ({ field, value, extra }) => {
    const newData = value ? { value: value, ...(extra ?? {}) } : extra ?? {};
    const newFormData = {
      ...formData,
      [field]: { ...formData[field], ...newData },
    };
    setFormData(newFormData);
  };
  const checkIsValid = () => {
    const updateDat = [];
    const isValid = validateForm(
      formData,
      (field, extra) => {
        updateDat.push({ key: field, update: extra });
      },
      activeStep === 2 ? customizeFields : finalizeFields
    );
    if (updateDat.length > 0) {
      const newD = { ...formData };
      updateDat.forEach(
        (data) => (newD[data.key] = { ...newD[data.key], ...data.update })
      );
      setFormData(newD);
    }
    return isValid;
  };
  const changeStep =
    (next = false) =>
    (e) => {
      const newStep = next ? activeStep + 1 : activeStep - 1;
      const activeStepNew =
        newStep < 0 ? 0 : newStep > stepLength - 1 ? stepLength : newStep;
      let isValid = true;

      if (next && activeStep > 1) {
        isValid = checkIsValid();
      }
      isValid && setActiveStep(activeStepNew);
    };
  const onSubmit = (e) => {
    const isValid = checkIsValid();
    if (isValid) {
      const messageStr = website ? "updat" : "creat";
      updateWebsiteMutation({ variables: getSubmitVariables(formData) })
        .then(({ data }) => {
          const newSiteData = data?.updateWebsite?.website;
          if (newSiteData) {
            updateActiveWebsite(null, newSiteData);
            enqueueSnackbar(`Website ${messageStr}ed successfully`, {
              variant: "success",
            });
            handleClose();
          } else {
            enqueueSnackbar("An error occurred fetching your website", {
              variant: "error",
            });
          }
        })
        .catch((e) => {
          enqueueSnackbar(
            e?.message || `An error has occurred ${messageStr}ed your website`,
            {
              variant: "error",
            }
          );
        });
    }
  };
  React.useEffect(() => {
    const planId =
      plans && formData.planId?.value ? formData.planId?.value : null;
    const plan = planId && plans.find((plan) => plan.id === planId);
    if (plan) {
      let d = [];
      const subdomain_option = user.agency?.subdomainDisplay;
      if (subdomain_option)
        d.push({ value: 0, text: user.agency.subdomainDisplay });
      if (plan.customDomain) {
        d = [
          ...d, //Adds subdomain options
          // Filters through domain options and adds verified domains
          ...domains
            .filter(({ verified }) => !!verified)
            .map((domain) => ({ value: domain.id, text: domain.domain })),
        ];
      }

      const l =
        d.length == 0
          ? [
              {
                text: "No domains available to assign to this site",
                value: "",
              },
            ]
          : [{ value: "", text: "------" }, ...d];
      updateFormData({ field: "domainId", extra: { options: l } });
    } else
      updateFormData({
        field: "domainId",
        extra: {
          options: [
            {
              text: "An error occurred finding available domains",
              value: "",
            },
          ],
        },
      });
  }, [domains, activeStep]);
  React.useEffect(() => {
    setNextDisabled(
      (activeStep === 0 &&
        (!formData?.templateId?.value ||
          (templates && templates.length === 0))) ||
        (activeStep === 1 &&
          (!formData?.planId?.value || (plans && plans.length === 0)))
    );
  }, [templates, plans, formData, activeStep]);
  console.log(buttonProps);
  return (
    <>
      <Box {...buttonBoxProps}>
        <GradientButton onClick={handleClickOpen} {...buttonProps}>
          {children}
        </GradientButton>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create your Website</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ borderTop: 1, borderColor: borderColor, pt: 3 }}
          >
            {modalDescriptions[activeStep]}
          </DialogContentText>
          <Box mt={3}>
            <WebsiteConfiguratorSections
              activeStep={activeStep}
              updateFormData={updateFormData}
              formData={formData}
              setTemplates={setTemplates}
              setPlans={setPlans}
              loading={loading}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="center"
            borderTop={1}
            flex={1}
            mx={3}
            spacing={3}
            pt={2}
            borderColor={borderColor}
          >
            {activeStep > 0 && (
              <GradientButton
                onClick={changeStep(false)}
                disabled={loading}
                // disabled={activeStep === 0}
              >
                Back
              </GradientButton>
            )}
            <WebsiteConfiguratorStepper
              activeStep={activeStep}
              steps={steps}
              icons={stepIcons}
            />
            <GradientButton
              loading={nextLoading || loading}
              disabled={nextDisabled || loading}
              disabledTooltip={{
                title: nextDisabled && nextDisabledTooltips[activeStep],
              }}
              onClick={
                activeStep == stepLength - 1 ? onSubmit : changeStep(true)
              }
            >
              {activeStep == stepLength - 1
                ? website
                  ? "Update"
                  : "Create"
                : "Next"}
            </GradientButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

const WebsiteConfiguratorSections = ({
  activeStep,
  formData,
  updateFormData,
  setTemplates,
  setPlans,
  loading,
}) => {
  switch (activeStep) {
    case 0:
      return (
        <>
          <Typography fontWeight={700} sx={{ mb: 3 }}>
            Available Templates
          </Typography>
          <WebsiteConfiguratorTemplateQuery
            updateFormData={updateFormData}
            formData={formData}
            setTemplates={setTemplates}
          />
        </>
      );
    case 1:
      return (
        <>
          <Typography fontWeight={700} sx={{ mb: 3 }}>
            Available Plans
          </Typography>
          <WebsiteConfiguratorPlanQuery
            updateFormData={updateFormData}
            formData={formData}
            setPlans={setPlans}
          />
        </>
      );
    case 2:
      return (
        <Stack spacing={3}>
          <RenderForm
            fieldKeys={customizeFields}
            formData={formData}
            updateFormData={updateFormData}
            loading={loading}
          />
        </Stack>
      );
    case 3:
      return (
        <Stack spacing={3}>
          <RenderForm
            fieldKeys={finalizeFields}
            formData={formData}
            updateFormData={updateFormData}
            loading={loading}
          />
        </Stack>
      );
  }
};

const RenderForm = ({ fieldKeys, formData, updateFormData, loading }) => {
  const [activeFormData, setActiveFormData] = React.useState([]);
  React.useEffect(() => {
    setActiveFormData(
      fieldKeys.map((field) => ({ name: field, ...formData[field] }))
    );
  }, [formData]);
  const updateField = (name, update) => {
    updateFormData({ field: name, extra: update });
  };
  return activeFormData.map(
    ({
      label,
      value,
      before,
      beforeTitle,
      beforeSubtitle,
      boxProps,
      icon: Icon,
      name,
      ...field
    }) => (
      <Box {...boxProps} key={name}>
        {(before || beforeTitle || beforeSubtitle) && (
          <Box mb={2}>
            {before}
            {beforeTitle && (
              <Typography variant="h6" fontWeight={700} gutterBottom>
                {beforeTitle}
              </Typography>
            )}
            {beforeSubtitle && (
              <Typography variant="body2">{beforeSubtitle}</Typography>
            )}
          </Box>
        )}
        <FormField
          updateField={updateField}
          loading={loading}
          value={value ?? ""}
          name={name}
          label={label}
          InputProps={
            Icon && {
              startAdornment: (
                <InputAdornment position="start">
                  <Icon />
                </InputAdornment>
              ),
            }
          }
          {...field}
        />
      </Box>
    )
  );
};

export default WebsiteConfigurator;
