import React from "react";
import {
  CircularProgress,
  Button,
  Tooltip,
  Box,
  IconButton,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Link } from "gatsby";

const defaultDisabledTooltip = "Please Fill in required fields";

const BtnWrap = ({ iconButton, className, ...props }) => {
  return iconButton ? (
    <IconButton size="large" {...props} />
  ) : (
    <Button className={className} {...props} />
  );
};

function GradientButton({
  onClick,
  loading,
  children,
  boxProps,
  disabled,
  fullWidth,
  fluid,
  color,
  tooltip: tp,
  disabledTooltip: dtp,
  toLink,
  linkProps,
  ...btnProps
}) {
  const disabledTooltip = dtp
    ? {
        ...dtp,
        title: loading ? "Loading..." : dtp?.title ?? defaultDisabledTooltip,
      }
    : {};
  const tooltip = disabled || loading ? disabledTooltip ?? tp : tp;
  const { sx, ...bProps } = btnProps;
  const d =
    color || bProps?.iconButton
      ? { color: color }
      : {
          // Gradient Button Color
          // height: 35,
          width: fullWidth ? "auto" : "100%",
          "& > span": { transition: "all .2s" },
          "&:hover > span": {
            filter: "drop-shadow(2px 3px 2px rgba(0,0,0,0.3))",
          },

          background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        };

  return (
    <LinkWrap to={toLink} {...linkProps}>
      <ToolTipWrap tooltip={tooltip}>
        <Box
          sx={
            bProps?.iconButton
              ? { position: "relative", cursor: "pointer" }
              : {
                  flex: fullWidth ? 1 : "none",
                  maxWidth: fullWidth ? "100%" : !fluid ? 120 : "auto",
                  width: fullWidth ? "100%" : "auto",
                  maxHeight: fluid ? 45 : "auto",
                  position: "relative",
                  cursor: "pointer",
                }
          }
          component="span"
          {...boxProps}
        >
          <BtnWrap
            variant="contained"
            onClick={onClick}
            disabled={disabled || loading}
            type="submit"
            {...bProps}
            color={color}
            sx={{ height: bProps?.iconButton ? null : 40, ...d, ...sx }}
          >
            {loading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                  }}
                />
              </Box>
            )}
            {bProps?.iconButton ? children : <span>{children}</span>}
          </BtnWrap>
        </Box>
      </ToolTipWrap>
    </LinkWrap>
  );
}

const LinkWrap = ({ component: R, to, children, ...props }) =>
  to ? (
    <Box
      sx={{ textDecoration: "none" }}
      to={to}
      component={R ?? Link}
      {...props}
    >
      {children}
    </Box>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

const ToolTipWrap = ({ tooltip, children }) =>
  tooltip ? (
    <Tooltip placement="top" arrow {...tooltip}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );

export default GradientButton;
