import React from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import ContainerLoader from "../ContainerLoader";
// import { DataGrid } from "@material-ui/data-grid";
// import { Navigate } from "react-router-dom";
// import { isFunction } from "../utilities/utils";
// import useResizeObserver from "use-resize-observer";

const EditObj = ({
  Component,
  appendRow,
  obj,
  setObj,
  baseUrl,
  updateQs,
  data,
  params,
  listKey,
}) => {
  React.useEffect(() => appendRow && appendRow(obj, baseUrl), [obj]);
  React.useEffect(() => {
    // console.log("data-change");
    if (data && data.hasOwnProperty(listKey) && data[listKey].length > 0) {
      setObj(data[listKey].find((i) => i.id === params.id));
    }
  }, [data]);
  return !obj ? (
    // <Navigate to={baseUrl} />
    //todo implement if needed
    <></>
  ) : (
    <React.Fragment>
      <ContainerLoader loading={!obj?.id} />
      {obj?.id && <Component object={obj} updateQs={updateQs} />}
    </React.Fragment>
  );
};

function QueryLoading({
  query,
  variables,
  Render,
  listKey,
  listTitle,
  listEmpty,
  pollInterval,
  refKey,
  renderProps,
  smallAlert,
  dataGrid,
  editRoute,
  EditComponent,
  appendRow,
  baseUrl,
  aboveTable: AboveTable,
  aboveTableProps,
  forceRender,
  sx,
  setUpdateQs,
  setData,
}) {
  const { columns, ...dataGridRem } = dataGrid || {};
  const { data, loading, error, refetch, updateQuery } = useQuery(query, {
    variables: variables || {},
    pollInterval: pollInterval || null,
  });
  // const { ref: onRefChange, height: gridHeight = 700 } = useResizeObserver();
  const [obj, setObj] = React.useState({});
  const updateQs = (id, dat, del, updateKey, updateKeyId) => {
    // id = Id of object being updated
    // dat = data of object
    // updateKey = For parent list
    // updateKeyId = For parent list id
    const deleteObj = !!del;
    //   console.log("update");
    // refetch();
    if (!listKey) throw new Error("No listKey Found - QueryLoading - 78");
    updateQuery((old) => {
      const isArray = Array.isArray(old[listKey]);
      if (!isArray) {
        if (id || del || updateKey || updateKeyId) {
          console.warn(
            "Options other than data are not supported here, data will be refetched QueryLoading - 82"
          );
          refetch();
        }

        return {
          [listKey]: dat,
        };
      }
      // if data is array
      let update = [...old[listKey]];
      let updated = false;
      const parentIndex = update.findIndex((a) => a.id === id);
      if (parentIndex >= 0) {
        updated = true;
        if (updateKey) {
          const index = update[parentIndex][updateKey]?.findIndex(
            (a) => a.id === updateKeyId
          );
          if (index >= 0) {
            let temp = [...update[parentIndex][updateKey]];
            if (deleteObj) {
              temp.splice(index, 1);
            } else {
              temp[index] = {
                ...temp[index],
                ...dat,
              };
            }
            update[parentIndex] = {
              ...update[parentIndex],
              [updateKey]: temp,
            };
          } else {
            updated = false;
          }
        } else {
          if (deleteObj) {
            update.splice(parentIndex, 1);
          } else {
            update[parentIndex] = { ...update[parentIndex], ...dat };
          }
        }
      }
      if (!updated && dat?.id) {
        if (updateKeyId) {
          if (parentIndex < 0) {
            throw "Invalid Parent Index";
          }
          update[parentIndex] = {
            ...update[parentIndex],
            [updateKey]: [...update[parentIndex][updateKey], dat],
          };
        } else {
          update.push(dat);
        }
      }
      return {
        [listKey]: update,
      };
    });
  };
  React.useEffect(() => {
    if (setUpdateQs) setUpdateQs(() => updateQs);
  }, []);
  React.useEffect(() => {
    if (setData && !loading && data && data?.[listKey] !== null) {
      setData(data[listKey]);
    }
  }, [data, loading]);
  return (
    <ContainerLoader sx={sx} loading={loading}>
      {!loading &&
        (!data && error ? (
          <Alert severity="error">
            {!smallAlert && <AlertTitle>An Error has Occurred</AlertTitle>}
            {smallAlert
              ? "An Error has Occurred"
              : "Please try again in a few moments, If the error persists please contact us!"}
          </Alert>
        ) : listKey &&
          data[listKey] &&
          !forceRender &&
          ((Array.isArray(data[listKey]) && data[listKey].length === 0) ||
            !Array.isArray(data[listKey])) ? (
          <Alert severity="info">
            {!smallAlert && <AlertTitle>Info</AlertTitle>}
            {listEmpty ? (
              listEmpty
            ) : (
              <React.Fragment>
                No {listTitle || "Item"}s found! -{" "}
                <strong>Please Create a {listTitle || "Item"}</strong>
              </React.Fragment>
            )}
          </Alert>
        ) : (
          <Render
            data={listKey ? data[listKey] : refKey ? data[refKey] : data}
            updateQs={updateQs}
            {...renderProps}
          />
        ))}
    </ContainerLoader>
  );
}

export default QueryLoading;
