import GradientButton from "./GradientButton";
import MutationLoading from "../gql/MutationLoading";
import React from "react"
const ActionButtons = ({
    actions,
    variables,
    closeModal,
    disabled,
    resetForm,
    setLoading,
    isValid,
    loading,
}) => {
    return actions.map(
        ({ mutation: { buttonProps, ...mutation } = {}, ...action }, i) =>
            mutation ? (
                <MutationLoading
                    key={i}
                    resetForm={action.resetOnSubmit === false ? null : resetForm}
                    disabled={disabled}
                    setLoading={setLoading}
                    variables={variables}
                    {...mutation}
                    buttonProps={{
                        type: "submit",
                        tooltip: !isValid && {
                            title: "Please fill in all required fields!",
                        },
                        ...buttonProps,
                    }}
                    closeModal={action.dismissOnSubmit === false ? null : closeModal}
                    loading={loading}
                />
            ) : (
                <GradientButton
                    key={i}
                    variant="contained"
                    boxProps={action.boxProps}
                    sx={{ mx: 2 }}
                    {...action?.buttonProps}
                >
                    {action.children}
                </GradientButton>
            )
    );
};

export default ActionButtons;