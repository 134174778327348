import React from "react";
import { Typography } from "@mui/material";
import Section from "./Section";
const Home = () => {
  return (
    <Section title="Todays Task's">
      <Typography>Nothing due for today. Your all Caught up!</Typography>
    </Section>
  );
};

export default Home;
