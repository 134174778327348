import React from "react";
import { Button, Box, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Form from "../../forms/Form";
import authQueries from "../../../queries/auth"
import { navigate } from "gatsby"
import { useSnackbar } from "notistack";

const UpdatePassword = ({
    email,
    code,
    setFinalCode,
    inline,
    callback,
    oldPassword,
    basePath
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const updateValue = ({ updated, invalidCode, errorMessage }) => {
        errorMessage && enqueueSnackbar(errorMessage, { variant: "error" });
        if (invalidCode) {
            setFinalCode(null);
        } else if (!errorMessage && updated) {
            !inline && navigate(`${basePath}/login/`);
            enqueueSnackbar("Password Updated Successfully", { variant: "success" });
        }
    };
    const genFields = {
        email: {
            readOnly: true,
            hide: true,
            value: email,
        },
        code: {
            readOnly: true,
            hide: true,
            value: code,
        },
        // ...(oldPassword ? {
        //   oldPassword: {
        //   value: "",
        //   label: "Old Password",
        //   required: true,
        //   type: "password",
        //   helperText: "Your current password",
        //   autoComplete: "new-password",
        //   inputProps: {
        //     "aria-describedby": "password-constraints",
        //   },
        // },
        // }:{}),
        password: {
            value: "",
            label: "New Password",
            required: true,
            type: "password",
            helperText: "Choose something secure and memorable",
            autoComplete: "new-password",
            inputProps: {
                "aria-describedby": "password-constraints",
            },
        },
        confirmPassword: {
            value: "",
            label: "Confirm New Password",
            required: true,
            type: "password",
            autoComplete: "new-password",
            inputProps: {
                "aria-describedby": "password-constraints",
            },
        },
    };
    return (
        <Box>
            {!inline && (
                <>
                    <Typography variant="h5" sx={{ mt: 2, mb: 3 }} color="text.secondary">
                        Change Password
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", mb: 1 }}>Email</Typography>
                    <Typography color="text.disabled" sx={{ mb: 3 }}>
                        {email}
                    </Typography>
                </>
            )}
            <Form
                genFields={genFields}
                actions={[
                    {
                        mutation: {
                            buttonProps: {
                                children: "Change Password",
                                endIcon: <SendIcon />,
                                type: "submit",
                                fluid: true,
                            },
                            query: authQueries.updatePassword,
                            fallbackError: "Invalid Code",
                            dataKey: "updatePassword",
                            ignoreSuccessMessage: true,
                            // successMessage: "Password Updated Successfully",
                            resetOnSubmit: false,
                            updateValue: updateValue,
                        },
                    },
                ]}
            />
        </Box>
    );
};

export default UpdatePassword;