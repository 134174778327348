import { DialogActions, Button } from "@mui/material";
import React from "react";

const btnSx = { mx: 2 };

const ModalDialogActions = ({ dismissModal, hideCancel, children }) => {
    return (
        <DialogActions
            sx={{
                borderTop: "1px solid #eee",
                py: 1,
                px: { xs: 1, md: 2 },
                boxShadow: 7,
            }}
        >
            {!hideCancel && (
                <Button sx={btnSx} onClick={dismissModal} variant="contained">
                    Cancel
                </Button>
            )}
            {children}
        </DialogActions>
    );
};

export default ModalDialogActions;