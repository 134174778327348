import React from "react";
import { useMutation } from "@apollo/client";
import { navigate } from "gatsby"
import authQueries from "../queries/auth"
import constants from "../utils/constants";
import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";
import PageLoader from "../components/PageLoader";
import { useSnackbar } from "notistack";
import { useLocation } from "@reach/router";
import { useInterval } from "../hooks/useInterval";
import { logoutClearDataStorage, setStorageTokens, getStorageRefreshToken, getStorageKeepMeLoggedIn } from "../services/auth"
const GraphAuth = ({ children }) => {
    const [refreshTokenMutation] = useMutation(authQueries.refreshToken);
    const [logoutMutation, { client }] = useMutation(authQueries.logoutQueries);
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [{ user, authData }, dispatch] = useStateValue();
    const [loading, setLoading] = React.useState(true);
    const [isKml, setIsKml] = React.useState(false);
    const [initialFetch, setInitialFetch] = React.useState(false);
    // Functions 
    const logoutCall = (e) => {
        setTokens({ token: null, refreshToken: authData.token, error: e });
        setLoading(false);
    };
    const getNext = () => {
        const params = new URLSearchParams(location.search);
        return params.get("next") || "/dashboard/";
    };
    const setUserCallback = ({ usersCourses, me }) => {
        if (constants.logInFieldRequired && !me?.[constants.logInFieldRequired]) {
            enqueueSnackbar(constants.logInFieldRequiredMessage ?? "You don't have access to this resource.", {
                variant: "error",
            });
            logoutCall();
        } else {
            usersCourses &&
                dispatch({
                    type: actionTypes.SET_COURSE_USERS,
                    courseUsers: usersCourses,
                });
            me &&
                dispatch({
                    type: actionTypes.SET_USER,
                    user: me,
                });
            !isKml &&
                enqueueSnackbar("Logged In Successfully", {
                    variant: "success",
                });
            loading && setLoading(false);
            // if (redirect) {
            //   navigate(getNext() || "/");
            //   loading && setLoading(false);
            // }
        }
    };
    const setUser = () => {
        !user &&
            client
                .query({ query: authQueries.getInitialUserData })
                .then(({ data }) => {
                    setUserCallback(data);
                })
                .catch((e) => {
                    e?.message && enqueueSnackbar(e?.message, { variant: "error" });
                    logoutCall(e);
                });
    };

    const logoutDataReset = (e) => {
        enqueueSnackbar(e?.message || "Logged out Successfully", {
            variant: e?.message ? "error" : "success",
        });
        logoutClearDataStorage();
        user &&
            dispatch({
                type: actionTypes.SET_USER,
                user: null,
            });
        dispatch({
            type: actionTypes.SET_AUTH_DATA,
            authData: {
                token: null,
                refreshToken: null,
                keepMeLoggedIn: false,
            },
        });
        navigate(getNext());
        loading && setLoading(false);
    };
    // if no token -> logout
    const setTokens = ({ token, refreshToken, keepMeLoggedIn, error }) => {
        if (!token && user) {
            refreshToken
                ? logoutMutation({
                    variables: {
                        refreshToken,
                    },
                })
                    .then((result) => logoutDataReset())
                    .catch(logoutDataReset)
                : logoutDataReset(error);
        } else if (!token) {
            logoutClearDataStorage();
        } else {
            setStorageTokens({ token, refreshToken, keepMeLoggedIn })
            !user && setUser();
        }
    };
    const refreshToken = () => {
        if ((initialFetch && !user) || !authData.refreshToken) return null;
        !initialFetch && setInitialFetch(true);
        !refreshTokenMutation && alert("Fatal Error - RFT 000000E");
        refreshTokenMutation &&
            refreshTokenMutation({
                variables: {
                    refreshToken: authData.refreshToken,
                },
            })
                .then(({ data }) => {
                    const result = data.refreshToken;
                    dispatch({
                        type: actionTypes.SET_AUTH_DATA,
                        authData: {
                            token: result.token,
                            refreshToken: result.refreshToken,
                        },
                    });
                })
                .catch(logoutCall);
    };
    const onLoad = () => {
        const refreshToken = getStorageRefreshToken()
        const kml = getStorageKeepMeLoggedIn()
        if (refreshToken && kml) {
            setIsKml(true);
            dispatch({
                type: actionTypes.SET_AUTH_DATA,
                authData: {
                    refreshToken: refreshToken,
                    keepMeLoggedIn: kml,
                },
            });
        } else {
            if (refreshToken) logoutClearDataStorage();
            setLoading(false);
            setInitialFetch(true);
        }
    };
    useInterval(refreshToken, constants.tokenRefreshInterval * 1000);
    React.useEffect(() => {
        onLoad();
    }, []);
    React.useEffect(() => {
        initialFetch ? setTokens(authData) : refreshToken();
    }, [authData]);

    return <PageLoader loading={loading}>{children}</PageLoader>;
};
export default GraphAuth;