import React from "react";
import { Stack, Chip, Box } from "@mui/material";
{
  /* Todo chips to switch between different displays , overall - views - requests - clicks - form submissions */
}

const dateLinks = [
  {
    label: "Last 24 Hours",
    dateAdjust: {
      //days: 1, // converted to seconds
      start: "yesterdayNow",
      end: "now", // add seconds to start
    },
  },
  {
    label: "Last 7 days",
    dateAdjust: {
      //days: 1, // converted to seconds
      start: "lastWeekNow",
      end: "now", // add seconds to start
    },
  },
  {
    label: "Last 28 Days",
    dateAdjust: {
      //days: 1, // converted to seconds
      days: 28,
      end: "now", // add seconds to start
    },
  },
  {
    label: "Last 90 days",
    dateAdjust: {
      //days: 1, // converted to seconds
      days: 90,
      end: "now", // add seconds to start
    },
  },
  {
    label: "Last year",
    dateAdjust: {
      //days: 1, // converted to seconds
      days: 365,
      end: "now", // add seconds to start
    },
  },
  {
    label: "All Time",
    dateAdjust: {
      //days: 1, // converted to seconds
      start: "allTime",
      end: "now", // add seconds to start
    },
  },
];
function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}
const AnalyticDisplay = ({ website }) => {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState(new Date());
  const updateDateSeconds = ({ date, seconds, add }) => {
    const dateCopy = new Date(date);
    const s = add ? seconds : -seconds;
    dateCopy.setSeconds(date.getSeconds() + s);

    return dateCopy;
  };
  const genDate = (datestr) => {
    const date = new Date();
    // Present day end will alway be today
    switch (datestr) {
      case "now":
        return date;
      case "yesterday":
        return updateDateSeconds({ date, seconds: 86400 });
      case "allTimeStart":
        return new Date("2023-04-13T00:00:00.000Z"); // todo replace with website creation date
      case "lastWeekNow":
        return updateDateSeconds({ date, seconds: 86400 * 7 });
      case "todayStart":
        date.setHours(0, 0, 0, 0);
        return date;
      case "weekStart":
        const d = getMonday(date);
        d.setHours(0, 0, 0, 0);
        return d;
      case "monthStart":
        return new Date(date.getFullYear(), date.getMonth(), 1);
      case "yearStart":
        return new Date(date.getFullYear(), 1, 1);
      case "yesterdayStart":
        const y = updateDateSeconds({ date, seconds: 86400 });
        y.setHours(0, 0, 0, 0);
        return y;
      case "lastWeekStart":
        return getMonday(updateDateSeconds({ date, seconds: 86400 * 7 }));
      case "lastMonthStart":
        const lms = updateDateSeconds({
          date: new Date(date.getFullYear(), date.getMonth(), 1),
          seconds: 86400 * 2,
        });
        return new Date(lms.getFullYear(), lms.getMonth(), 1);
      case "lastYearStart":
        const lys = updateDateSeconds({
          date: new Date(date.getFullYear(), 1, 1),
          seconds: 86400 * 2,
        });
        return new Date(lys.getFullYear(), 1, 1);
      default:
        return date;
    }
  };
  const updateDates = ({ seconds, days, start, end }) => {
    if (days && !seconds) seconds = days * 86400;
    if (!start && !end) return; // Required start date or end
    let sDate = start && genDate(start);
    let eDate = end && genDate(end);
    if (start)
      eDate = updateDateSeconds({ date: sDate, seconds: seconds, add: true });
    if (end) sDate = updateDateSeconds({ date: eDate, seconds: seconds });
    setStartDate(sDate);
    setEndDate(eDate);
  };
  return (
    <>
      <Stack>
        <Box>
          {dateLinks.map(({ dateAdjust, ...chipProps }) => (
            <Chip
              color="primary"
              sx={{ mr: 2 }}
              variant="outlined"
              onClick={() => updateDates(dateAdjust)}
              {...chipProps}
            />
          ))}
        </Box>
        <Box></Box>
      </Stack>
    </>
  );
};

export default AnalyticDisplay;
