import { gql } from "@apollo/client";
export const ADDRESS_FRAGMENTS = gql`
  fragment AddressParts on AddressType {
    id
    name
    line1
    line2
    cityText
    county
    postcode
    country
    createdAt
    updatedAt
  }
`;
export const WEBSITE_SERVICE_FRAGMENTS = gql`
  fragment WebsiteServiceParts on WebsiteServiceType {
    id
    name
    slug
    imageUrl
    locked
  }
`;
export const AGENCY_TYPE_FRAGMENTS = gql`
  ${ADDRESS_FRAGMENTS}
  ${WEBSITE_SERVICE_FRAGMENTS}
  fragment AgencyParts on AgencyType {
    id
    logoUrl
    logoName
    name
    slug
    themeColor
    subdomain
    subdomainDisplay
    verified
    createdAt
    updatedAt
    servicesOffered {
      ...WebsiteServiceParts
    }
    address {
      ...AddressParts
    }
  }
`;

export const AGENCY_PORTFOLIO_FRAGMENTS = gql`
  ${WEBSITE_SERVICE_FRAGMENTS}
  fragment AgencyPortfolioParts on AgencyPortfolioType {
    id
    agency {
      id
    }
    createdAt
    description
    displayOnWebsite
    listOrder
    link
    portfolioType {
      ...WebsiteServiceParts
    }
    previewImageUrl
    thumbnailUrl
    previewImageName
    thumbnailName
    shortDescription
    slug
    title
    updatedAt
    dateCompleted
    yearCompleted
  }
`;

export const DOMAIN_FRAGMENTS = gql`
  fragment DomainParts on DomainType {
    createdAt
    deleted
    domain
    domainHost
    id
    updatedAt
    verified
    verifyTextRecord
  }
`;
export const DOMAIN_EMAIL_FRAGMENTS = gql`
  fragment DomainEmailParts on DomainEmailType {
    id
    fromEmail
    accountEmail
    account
    name
    host
    deleted
    createdAt
    updatedAt
  }
`;

export const WEBSITE_TEMPLATE_FRAGMENTS = gql`
  fragment WebsiteTemplateParts on WebsiteTemplateType {
    id
    thumbnailUrl
    previewUrl
    internalAccessor
    description
    published
    name
    updatedAt
    createdAt
  }
`;

export const WEBSITE_PLAN_FRAGMENTS = gql`
  fragment WebsitePlanParts on WebsitePlanType {
    id
    name
    slug
    price
    description
    customDomain
    annual
    createdAt
    updatedAt
  }
`;
export const WEBSITE_PLAN_SUBSCRIPTION_FRAGMENTS = gql`
  ${WEBSITE_PLAN_FRAGMENTS}
  fragment WebsitePlanSubscriptionParts on WebsitePlanSubscriptionType {
    plan {
      ...WebsitePlanParts
    }
    price
    firstPayment
    nextPayment
    lastPayment
    lastPaymentAmount
    schedule
    recurring
    id
  }
`;
export const WEBSITE_FRAGMENTS = gql`
  ${WEBSITE_TEMPLATE_FRAGMENTS}
  ${WEBSITE_PLAN_SUBSCRIPTION_FRAGMENTS}
  fragment WebsiteParts on WebsiteType {
    id
    name
    subUrl
    subdomain
    domain {
      id
      domain
      verified
    }
    template {
      ...WebsiteTemplateParts
    }
    planSubscription {
      ...WebsitePlanSubscriptionParts
    }
    active
    contactEmail
    contactNumber
    facebookPixelId
    googleAnalyticsId
    gtagId
    tiktokPixelId
    facebookPage
    instagram
    linkedin
    tiktok
    twitter
    updatedAt
    createdAt
    brandDisplayName
    pageTitle
    pageSlogan
    pageDescription
    aboutUs
    aboutUsVideoLink
    brandDisplayName
    portfolioDescription
    servicesDescription
    services {
      name
    }
    portfolios {
      title
      shortDescription
      description
      yearCompleted
      link
      thumbnailUrl
      previewImageUrl
      portfolioType {
        name
      }
    }
  }
`;

// servicesOffered;
export const AUTH_FRAGMENTS = gql`
  ${AGENCY_TYPE_FRAGMENTS}
  fragment UserParts on UserType {
    id
    email
    firstName
    lastName
    number
    dateOfBirth
    emailAuthenticated
    isSuperuser
    profileImg
    themeColor
    coursesEnrolledIn
    hoiPortalAccess
    dashboardAccess
    agencyManagerAccess
    leadsAccess
    agency {
      ...AgencyParts
    }
  }
`;
