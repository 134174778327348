import React from "react";
import authQueries from "../../../queries/auth";
import { navigate } from "gatsby";
import { useStateValue } from "../../../StateProvider";
import { actionTypes } from "../../../reducer";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import SendIcon from "@mui/icons-material/Send";
import Form from "../../forms/Form";

function LoginForm({ setEmail, basePath }) {
    const { enqueueSnackbar } = useSnackbar();
    const genFields = {
        email: {
            value: "",
            label: "Email",
            errorText: "Please Enter an Email",
            required: true,
            autoComplete: "email",
            type: "email",
        },
        password: {
            value: "",
            label: "Password",
            required: true,
            type: "password",
            helperTextComponent: () => (
                <Button
                    sx={{ float: "right", mr: -2, mt: 1 }}
                    // component={Link}
                    onClick={() => {
                        navigate(`${basePath}/login/forgot-password`);
                    }}
                >
                    Forgot your password?
                </Button>
            ),
            autoComplete: "current-password",
            inputProps: {
                "aria-describedby": "password-constraints",
            },
            sx: { mb: 1 },
        },
        keepLoggedIn: {
            type: "checkbox",
            value: true,
            label: "Keep me Logged in",
        },
    };

    const [fields, setFields] = React.useState(genFields);
    React.useEffect(() => {
        setEmail(fields?.email?.value);
    }, [fields?.email?.value]);

    const [, dispatch] = useStateValue();
    const loginUpdate = ({ token, refreshToken, variables: vars }) => {
        if (token == null) {
            enqueueSnackbar("Incorrect Email or Password!", { variant: "error" });
        } else {
            dispatch({
                type: actionTypes.SET_AUTH_DATA,
                authData: {
                    token: token,
                    refreshToken: refreshToken,
                    keepMeLoggedIn: vars.keepLoggedIn ? true : false,
                },
            });
        }
    };
    return (
        <Form
            fields={fields}
            setFields={setFields}
            genFields={genFields}
            actions={[
                {
                    mutation: {
                        buttonProps: {
                            children: "Sign In",
                            endIcon: <SendIcon />,
                            type: "submit",
                            fluid: true,
                        },
                        query: authQueries.getToken,
                        fallbackError: "Incorrect Email or Password!",
                        dataKey: "action",
                        updateValue: loginUpdate,
                        resetOnSubmit: false,
                        ignoreSuccessMessage: true,
                    },
                },
            ]}
        />
    );
}

export default LoginForm;