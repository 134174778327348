import React from "react";
import Section from "../../Section";
import {
  Typography,
  Stack,
  Box,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import WebsiteConfigurator from "./WebsiteConfigurator";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import GradientButton from "../../../forms/GradientButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import AnalyticDisplay from "./AnalyticDisplay";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { paths } from "../../../../utils/dashboardConstants";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { Link } from "gatsby";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import ScheduleSendRoundedIcon from "@mui/icons-material/ScheduleSendRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
const ActiveWebsite = ({ data: website, updateQs, domains }) => {
  if (!website) {
    return (
      <>
        <Typography variant="h6" align="center" fontWeight={700} gutterBottom>
          Start your journey!
        </Typography>
        <WebsiteConfigurator domains={domains} updateActiveWebsite={updateQs}>
          Create your Website
        </WebsiteConfigurator>
      </>
    );
  }
  return (
    <>
      <Typography variant="h5" fontWeight={700}>
        {website.name}
      </Typography>
      <Section flat title="Template">
        <Stack direction="row" alignItems="center" alignContent="center">
          <Typography
            variant="h6"
            color="text.secondary"
            fontWeight={700}
            sx={{ mr: 3 }}
          >
            {website.template?.name ?? "Please choose a template"}
          </Typography>
          {website.template && (
            <>
              <GradientButton
                component="a"
                href={paths.agency.templatePreviewManager.replace(
                  ":websiteId",
                  website.id
                )}
                tooltip={{ title: "Preview your Site", placement: "bottom" }}
                target="_blank"
                iconButton
                color="primary"
              >
                <OpenInNewRoundedIcon />
              </GradientButton>
              <GradientButton
                component="a"
                href={website.template.previewUrl}
                tooltip={{ title: "Preview Template", placement: "bottom" }}
                target="_blank"
                iconButton
                color="secondary"
              >
                <OpenInNewRoundedIcon />
              </GradientButton>
              <GradientButton
                // component="a"
                // href={website.template.previewUrl}
                disabledTooltip={{
                  title:
                    "All tasks must be completed before you can generate your site",
                  placement: "bottom",
                }}
                // target="_blank"
                iconButton
                color="secondary"
                disabled
              >
                <LocalShippingRoundedIcon />
              </GradientButton>
              <GradientButton
                // component="a"
                // href={website.template.previewUrl}
                disabledTooltip={{
                  title:
                    "A mystical function that will do everything in its power to take your website to production once it has been generated",
                  placement: "bottom",
                }}
                // target="_blank"
                iconButton
                color="secondary"
                disabled
              >
                <ScheduleSendRoundedIcon />
              </GradientButton>
            </>
          )}
        </Stack>
      </Section>
      {website.tasks && (
        <Section title="Getting Started" flat>
          <List>
            {website.tasks.map(
              ({ title, description, completed, required }) => (
                <ListItem>
                  <ListItemIcon>
                    {completed ? (
                      <CheckRoundedIcon color="success" />
                    ) : (
                      <CloseRoundedIcon color="danger" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={title} secondary={description} />
                </ListItem>
              )
            )}
          </List>
        </Section>
      )}
      <Section flat title="Manage">
        <Stack direction="row" spacing={3}>
          <WebsiteConfigurator
            website={website}
            // buttonBoxProps={{ ml: "auto" }}
            buttonProps={{
              iconButton: true,
              tooltip: { title: "Update Configuration", placement: "bottom" },
            }}
            domains={domains}
            updateActiveWebsite={updateQs}
          >
            <CreateRoundedIcon sx={{ fontSize: 50 }} />
          </WebsiteConfigurator>
          <Box>
            <GradientButton
              component="a"
              href="https://business.facebook.com"
              tooltip={{ title: "Manage your Pixel", placement: "bottom" }}
              target="_blank"
              iconButton
            >
              <FacebookIcon sx={{ fontSize: 50 }} />
            </GradientButton>
          </Box>
        </Stack>
      </Section>
      <Section
        flat
        title="Analytics"
        titleEnd={
          <Tooltip
            title={
              <>
                <strong>Features Coming Soon</strong>
                <ul style={{ paddingLeft: 10 }}>
                  <li>Date filter</li>
                  <li>Graph Display</li>
                  <li>Graph Styles</li>
                </ul>
              </>
            }
          >
            <IconButton>
              <HelpRoundedIcon />
            </IconButton>
          </Tooltip>
        }
      >
        <AnalyticDisplay website={website} />
      </Section>
    </>
  );
};

export default ActiveWebsite;
