import React from "react";
import Section from "../Section";
import { Grid, Stack, IconButton, Typography, Box } from "@mui/material";
import { agencyShortcuts } from "../../../utils/dashboardConstants";
import { Link } from "gatsby";
import Button from "../../forms/Button";
import agencyQueries from "../../../queries/agency";
import { actionTypes } from "../../../reducer";
import { useStateValue } from "../../../StateProvider";
import { useSnackbar } from "notistack";
import Image from "../../layout/ImageDisplay";
import constants from "../../../utils/constants";

const CreateUpdateAgency = ({ agency, dispatch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const genFields = {
    name: {
      value: agency?.name ?? "",
      label: "Name",
    },
    subdomain: {
      value: agency?.subdomain ?? "",
      required: true,
      label: "Subdomain",
      helperText: `Used to generate your ${constants.agencyName} domain`,
    },
    logo: {
      required: false,
      type: "file",
      label: "Logo",
      accept: ".png, .jpg, .jpeg",
      helperText: !agency && "Can be uploaded later",
      displayPreview: agency?.logoUrl
        ? {
            url: agency.logoUrl,
            name: agency.logoName,
          }
        : null,
    },
    themeColor: {
      type: "color",
      value: agency?.themeColor ?? "",
      label: "Theme Color",
      required: false,
      helperText: `Your agency dashboard will be styled with this colour ${
        agency ? "" : "(Can be changed later)"
      }`,
    },
  };
  return (
    <Button
      modal={{
        label: "create-agency",
        title: "Create your Agency",
        dialogProps: {
          scroll: "paper",
          fullWidth: true,
          maxWidth: "sm",
        },
        form: true,
        renderProps: {
          genFields: genFields,
          actions: [
            {
              mutation: {
                dataKey: "updateAgency",
                query: agencyQueries.updateAgency,
                updateValue: ({ agency }) => {
                  if (agency == null) {
                    enqueueSnackbar(
                      "An error occurred when creating your agency",
                      {
                        variant: "error",
                      }
                    );
                    return null;
                  }
                  dispatch({
                    type: actionTypes.SET_USER_AGENCY,
                    agency: agency,
                  });
                },
                successMessage: `Your Agency has been ${
                  agency ? "updated" : "created"
                } Successfully!`,
                //   updateValue: ({ file }) =>
                //     updateData &&
                //     updateData(file.fileType.id, file, false, "files", file.id),

                buttonProps: {
                  children: agency ? "Update" : "Create",
                },
              },
              resetOnSubmit: false,
            },
          ],
        },
      }}
    >
      {agency ? "Update" : "Create"}
    </Button>
  );
};

const AgencyManagerHome = () => {
  const [{ user }, dispatch] = useStateValue();
  const agency = user.agency;
  if (!agency) {
    return (
      <Section sx={{ textAlign: "center" }}>
        <Typography variant="h6" fontWeight={700} sx={{ mb: 2 }}>
          Create your Agency
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          And Unlock the full power of the Manager!
        </Typography>
        <CreateUpdateAgency dispatch={dispatch} />
      </Section>
    );
  }
  return (
    <>
      <Section
        title={agency.name}
        titleBefore={
          agency?.logoUrl ? <Image src={agency.logoUrl} width={70} /> : <></>
        }
        titleEnd={
          <Box ml="auto">
            <CreateUpdateAgency agency={agency} dispatch={dispatch} />
          </Box>
        }
      >
        <Typography>
          Your {constants.agencyName} Domain:{" "}
          {agency?.subdomain
            ? `${agency.subdomain}.${constants.agencyDomain}`
            : "Not Set"}
        </Typography>
      </Section>
      <Section title="Shortcuts">
        <Stack>
          {agencyShortcuts.map((shortcut) => (
            <Box>
              <IconButton
                component={Link}
                to={shortcut.to}
                title={shortcut.title}
              >
                <shortcut.icon sx={{ fontSize: 50 }} />
              </IconButton>
            </Box>
          ))}
        </Stack>
      </Section>
      <Section title="Setup">
        <Typography>Welcome to the Agency Manager your all setup!</Typography>
      </Section>
    </>
  );
};

export default AgencyManagerHome;
