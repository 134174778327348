import { Button, Box, Typography } from "@mui/material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { useGetQuery } from "../../../hooks/useGetQuery";
import Form from "../../forms/Form";
import UpdatePassword from "./UpdatePassword";
import authQueries from "../../../queries/auth.js"
import constants from "../../../utils/constants"
const ForgotPasswordCode = ({ email, setFinalEmail, urlCode, basePath }) => {
    const [finalCode, setFinalCode] = React.useState();
    const [tempCode, setTempCode] = React.useState(urlCode ?? "");
    const genCodeFields = {
        code: {
            int: true,
            // name: "token",
            value: tempCode,
            label: "Code",
            errorText: "Please enter a Code",
            required: true,
            autoComplete: "one-time-email",
            inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
        },
        email: {
            readOnly: true,
            hide: true,
            value: email,
        },
    };
    const updateValue = ({ valid, variables: { code } }) => {
        setFinalCode(valid ? code : null);
        setTempCode(valid ? code : null);
    };
    return finalCode ? (
        <UpdatePassword
            email={email}
            code={finalCode}
            setFinalCode={setFinalCode}
            basePath={basePath}
        />
    ) : (
        <Box>
            <Typography variant="h6" color="text.secondary">
                Your code has been sent!
            </Typography>
            <Typography color="text.secondary" sx={{ mt: 2, mb: 3 }}>
                Code expires in 15 Minutes
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>Email</Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <Typography sx={{ mr: 2 }}>{email}</Typography>
                <Button onClick={(e) => setFinalEmail(null)}>Change</Button>
            </Box>
            <Form
                genFields={genCodeFields}
                actions={[
                    {
                        mutation: {
                            buttonProps: {
                                children: "Verify",
                                endIcon: <SendIcon />,
                                type: "submit",
                                fluid: true,
                            },
                            query: authQueries.verifyResetPasswordCode,
                            fallbackError: "Invalid Code",
                            dataKey: "verifyResetPasswordCode",
                            successMessage: "Code Verified Successfully",
                            sendOnLoad: !!urlCode,
                            resetOnSubmit: false,
                            updateValue: updateValue,
                            ignoreSuccessMessage: !!urlCode,
                        },
                    },
                ]}
            />
        </Box>
    );
};
// verifyResetPasswordCode;
// updatePassword;
const ForgotPassword = ({ email, basePath }) => {
    const params = useGetQuery();
    const userId = params.get(constants.forgotPasswordParams.userId);
    const urlCode = params.get(constants.forgotPasswordParams.urlCode);
    const [finalEmail, setFinalEmail] = React.useState();
    const [tempEmail, setTempEmail] = React.useState("");
    const genFields = {
        email: {
            value: tempEmail,
            label: "Email",
            errorText: "Please Enter an Email",
            required: true,
            autoComplete: "email",
            type: "email",
        },
        ...(userId && {
            userId: {
                hide: true,
                readOnly: true,
                value: userId,
            },
        }),
    };

    const updateValue = ({ email, expiryData }) => {
        setFinalEmail(email);
        setTempEmail(email);
    };
    return finalEmail ? (
        <ForgotPasswordCode
            urlCode={urlCode}
            email={finalEmail}
            setFinalEmail={setFinalEmail}
            basePath={basePath}
        />
    ) : (
        <>
            <Typography
                sx={{ mb: 3, mt: 1, color: "text.secondary" }}
                variant="body1"
            >
                Please enter the email linked to your account, and we'll send you an
                email to reset your password.
            </Typography>
            <Form
                //   fields={fields}
                //   setFields={setFields}
                genFields={genFields}
                actions={[
                    {
                        mutation: {
                            buttonProps: {
                                children: "Send Code",
                                endIcon: <SendIcon />,
                                type: "submit",
                                fluid: true,
                            },
                            query: authQueries.createResetPasswordCode,
                            fallbackError: "Invalid Email",
                            dataKey: "createResetPasswordCode",
                            successMessage: ({ message = "Email has been Sent!" }) => message,
                            sendOnLoad: !!userId,
                            ignoreSuccessMessage: !!userId,
                            resetOnSubmit: false,
                            updateValue: updateValue,
                            returnVariables: true,
                        },
                    },
                ]}
            />
        </>
    );
    //   return (
    // <Box
    //   component="form"
    //   onSubmit={handleSubmit}
    //   sx={{
    //     p: 2,
    //     width: "100%",
    //     minWidth: "100%",
    //   }}
    // >
    //   <Typography variant="h6">We have sent an email to</Typography>
    //   <Box
    //     sx={{
    //       my: 2,
    //       display: "flex",
    //       alignItems: "flex-end",
    //     }}
    //   >
    //     <Typography color="textSecondary">{email}</Typography>
    //     <Box
    //       sx={{
    //         ml: 2,
    //       }}
    //     >
    //       <Link to="/login">
    //         <Typography variant="subtitle2"> Change</Typography>
    //       </Link>
    //     </Box>
    //   </Box>
    //   <Form
    //     fields={fields}
    //     setFields={setFields}
    //     loading={loading}
    //     setValid={setValid}
    //   />
    //   <GradientButton
    //     endIcon={<SendIcon />}
    //     loading={loading}
    //     disabled={loading || !valid}
    //     // onClick={handleSubmit}
    //     type="submit"
    //     fullWidth="true"
    //   >
    //     Verify
    //   </GradientButton>
    // </Box>
    //   );
};
export default ForgotPassword;