import { Typography, Stack, IconButton, Box } from "@mui/material";
import React from "react";
import NavList from "./NavList";
import { useStateValue } from "../../StateProvider";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import constants from "../../utils/constants";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

const Nav = () => {
    const maxWidth = 280;
    const [{ user }] = useStateValue();
    return (
        <Stack
            sx={{ p: 2, color: "#fff", maxWidth: maxWidth, minHeight: "100vh" }}
            component="nav"
            spacing={2}
        >
            <Typography component="h2" variant="h6" fontWeight="bold">
                Agency Manager
            </Typography>
            <NavList />
            <Stack direction="row" spacing={2}>
                <Box>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        variant="dot"
                    >
                        <Avatar
                            alt={`${user.firstName} ${user.lastName}`}
                            src={user.profileImg && constants.formatMediaUrl(user.profileImg)}
                        >{`${user.firstName[0]}${user.lastName[0]}`}</Avatar>
                    </StyledBadge>
                </Box>
                <Stack>
                    <Typography
                        fontWeight="bold"
                        gutterBottom
                        variant="body2"
                    >{`${user.firstName} ${user.lastName}`}</Typography>
                    <Typography
                        fontWeight="bold"
                        variant="caption"
                        sx={{ color: "rgba(255,255,255,.5)" }}
                    >{`{User Role}`}</Typography>
                </Stack>
                <IconButton sx={{ color: "#fff" }}>
                    <MoreVertRoundedIcon />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default Nav;
