import React from "react";
import { gql, useQuery } from "@apollo/client";
import One from "../../pages/website_templates/one";
import Two from "../../pages/website_templates/two";

const WEBSITE_QUERY = gql`
  query Website($id: ID) {
    website(id: $id) {
      contactEmail
      contactNumber
      facebookPixelId
      googleAnalyticsId
      gtagId
      tiktokPixelId
      twitterUrl
      facebookPageUrl
      instagramUrl
      tiktokUrl
      linkedinUrl
      updatedAt
      createdAt
      brandDisplayName
      pageTitle
      pageSlogan
      pageDescription
      aboutUs
      aboutUsVideoLink
      portfolioDescription
      servicesDescription
      agency {
        name
      }
      services {
        name
        imageUrl
      }
      portfolios {
        title
        shortDescription
        description
        yearCompleted
        link
        thumbnailUrl
        previewImageUrl
        portfolioType {
          name
        }
      }
      template {
        internalAccessor
      }
    }
  }
`;

const PreviewWrapper = ({ websiteId }) => {
  // Generate a preview using a website id from url
  // Must be run with a graphql link, so descendent of dashboard and GraphAuth, giving us access to user and auth to verify website ownership
  const { loading, error, data } = useQuery(WEBSITE_QUERY, {
    variables: { id: websiteId },
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  const website = data.website;
  const a = website.template.internalAccessor;
  if (a === "ONE") {
    return <One website={website} />;
  } else if (a === "TWO") return <Two website={website} />;
  return <div>Invalid Template</div>;
};

export default PreviewWrapper;
